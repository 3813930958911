import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Select, { AsyncSelect } from '../components/Select';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import ListGroup, { ListGroupItem } from '../../../components/bootstrap/ListGroup';
import Badge from '../../../components/bootstrap/Badge';
import Avatar from '../../../components/Avatar';
import USERS from '../../../common/data/userDummyData';
import UserModule from '../../../modules/UserModule';
import UserTypeModule from '../../../modules/bakti/UserTypeModule';
import { readFromLocalStorage, writeToLocalStorage } from '../utils';
import Actions from './Actions';
import { getQueryParams } from '../../../helpers/helpers';
import { addMember, removeMember } from '../../../enums/activityBoard';

export const MemberOptions = [
	{ value: 'owner', label: 'Owner', color: 'info', rank: 10 },
	{ value: 'member', label: 'Member', color: 'success', rank: 20 },
	{ value: 'spectator', label: 'Spectator', color: 'warning', rank: 30 },
	{ value: 'guest', label: 'Guest', color: 'secondary', rank: 40 },
];

export const MemberColors = {
	owner: 'info',
	member: 'success',
	spectator: 'warning',
	guest: 'secondary',
};

const FormCreateMember = ({ isOpen, setIsOpen, title, size, id, onSuccess, setLoading }) => {
	const { board_code: boardCode } = getQueryParams();
	const currentUser = readFromLocalStorage('board_user');
	const listMembers = readFromLocalStorage('board_members');

	const [listRole, setListRole] = useState([]);

	const [user, setUser] = useState(null);
	const [role, setRole] = useState(null);
	const [errors, setErrors] = useState({});

	const userRef = useRef(null);

	const formik = useFormik({
		initialValues: { members: [] },
		validationSchema: Yup.object({}),
		onReset: () => {
			userRef.current?.clearValue();
			setUser(null);
			setRole(null);
			setErrors({});
			setIsOpen(false);
		},
		onSubmit: (values, { resetForm }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: 'Do you want to save this?',
					icon: 'question',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					confirmButtonText: 'Save',
					cancelButtonColor: '#d33',
					cancelButtonText: `Don't Save`,
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						setLoading(true);
						const newMembers = values.members?.map((u) => ({
							username: u?.username,
							name: u?.name,
							email: u?.email,
							role: u?.role,
							created_at: u?.created_at,
							isDraft: u?.isDraft ?? false,
							isWaitingApproval: u?.isWaitingApproval ?? false,
						}));
						const arrNewMemberName = newMembers.map((m) => m.name);

						let activity_type = addMember.activityType;
						let activity_detail = addMember.generateActivityDetail({
							username: currentUser.name,
							addedUsername: arrNewMemberName.toString(),
						});
						if (listMembers?.length > values.members.length) {
							const arrRemovedMemberName = listMembers
								.filter((m) => !arrNewMemberName.includes(m.name))
								.map((m1) => m1.name);
							activity_type = removeMember.activityType;
							activity_detail = removeMember.generateActivityDetail({
								username: currentUser.name,
								removedUsername: arrRemovedMemberName.toString(),
							});
						}
						Actions.updateBoard(boardCode, {
							members: JSON.stringify(newMembers),

							// default
							actor: JSON.stringify(currentUser),
							activity_type,
							activity_detail,
						})
							.then(() => {
								writeToLocalStorage('board_members', newMembers);
								Swal.fire({
									heightAuto: false,
									title: 'Success!',
									text: 'Member update successfully',
									icon: 'success',
								}).then(() => {
									resetForm();
									onSuccess();
								});
							})
							.catch(() => {})
							.finally(() => {
								setLoading(false);
							});
					}
				});
			} catch (err) {
				setLoading(true);
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			}
		},
	});

	useEffect(() => {
		const fetch = async () => {
			try {
				formik.setFieldValue('members', listMembers);

				UserTypeModule.read({ showAll: true })
					.then((response) => {
						const list = [...response.foundData].filter((e) => e.value !== 'owner');
						setListRole(list);
					})
					.catch(() => {
						setListRole([]);
					});
			} catch (err) {
				//
			}
		};

		if (isOpen) fetch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleAdd = () => {
		setErrors({});

		if (!user || !role) {
			if (!user) setErrors((prev) => ({ ...prev, user: 'Required' }));
			if (!role) setErrors((prev) => ({ ...prev, role: 'Required' }));
			return;
		}

		if (formik.values.members.find((e) => e.username === user.username)) {
			setErrors((prev) => ({ ...prev, user: 'User already exists' }));
			return;
		}

		const newMembers = [
			...formik.values.members,
			{
				isDraft: true,
				username: user.username,
				name: user.name || '',
				email: user.email || '',
				role: role.value,
				...role,
			},
		];
		formik.setFieldValue('members', newMembers);

		setUser(null);
		setRole(null);
		userRef.current?.clearValue();
	};

	const handleRemove = (index) => {
		const newMembers = [...formik.values.members];
		newMembers.splice(index, 1);
		formik.setFieldValue('members', newMembers);
	};

	return (
		<Modal
			setIsOpen={formik.resetForm}
			isOpen={isOpen}
			titleId={id}
			size={size}
			isCentered
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.resetForm}>
				<ModalTitle id={id}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row py-1'>
					<div className='col-sm-12 col-lg-7'>
						<FormGroup id='user' label='Employee'>
							<AsyncSelect
								ref={userRef}
								placeholder='Type here..'
								loadOptions={(e) => UserModule.loadOptions(e)}
								cacheOptions
								defaultOptions
								defaultValue={user}
								value={user}
								onChange={setUser}
								isValid={!errors?.user}
								isSubmitted={!!errors?.user}
								invalidFeedback={errors?.user}
							/>
						</FormGroup>
					</div>

					<div className='col-sm-10 col-lg-4'>
						<FormGroup id='role' label='Role'>
							<Select
								placeholder='Select one'
								options={listRole}
								defaultValue={role}
								value={role}
								onChange={setRole}
								isValid={!errors?.role}
								isSubmitted={!!errors?.role}
								invalidFeedback={errors?.role}
							/>
						</FormGroup>
					</div>

					<div className='col-sm-2 col-lg-1' style={{ marginTop: '25px' }}>
						<Button color='success' type='button' onClick={handleAdd} className='me-2'>
							Add
						</Button>
					</div>
				</div>

				<div className='py-1 mt-3'>
					<ListGroup isFlush>
						{formik.values.members?.map((member, index) => (
							<ListGroupItem key={member.username}>
								<div className='d-flex align-items-center'>
									<div className='flex-grow-1'>
										<div className='d-flex'>
											<div className='flex-shrink-0'>
												<Avatar
													src={USERS.JOHN.src}
													srcSet={USERS.JOHN.srcSet}
													color={USERS.JOHN.color}
													size={36}
												/>
											</div>
											<div className='mx-2'>
												<div>{member?.name}</div>
												<div className='small text-muted'>
													{member?.username}
												</div>
											</div>
											{member?.isWaitingApproval && (
												<div className='mx-2'>
													<div className='small text-info'>
														Waiting for Approval
													</div>
												</div>
											)}
										</div>
									</div>
									<div className='mx-1'>
										<h5>
											<Badge color={MemberColors[member?.role]}>
												{member?.role}
											</Badge>
										</h5>
									</div>
									{member?.role !== 'owner' &&
										['owner'].includes(currentUser?.role) &&
										(member?.isDraft || !member?.isWaitingApproval) && (
											<div className='mx-1'>
												<Button
													isOutline
													icon='Close'
													color='danger'
													type='button'
													size='sm'
													onClick={() => handleRemove(index)}
													className='mx-1'
												/>
											</div>
										)}
								</div>
							</ListGroupItem>
						))}
					</ListGroup>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					isLink
					color='info'
					type='button'
					onClick={formik.resetForm}
					className='mx-1'>
					Close
				</Button>

				<Button
					icon='Save'
					color='info'
					type='button'
					onClick={formik.handleSubmit}
					className='mx-1'>
					Save
				</Button>
			</ModalFooter>
		</Modal>
	);
};

FormCreateMember.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	onSuccess: PropTypes.func,
	setLoading: PropTypes.func,
};
FormCreateMember.defaultProps = {
	id: 'formAddMember',
	title: 'Add Member',
	size: 'lg',
	onSuccess: () => {},
	setLoading: () => {},
};

export default FormCreateMember;
