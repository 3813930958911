import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookie } from 'react-use';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import Swal from 'sweetalert2';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../../layout/SubHeader/SubHeader';
import PageLayoutHeader from '../../../pages/common/Headers/PageLayoutHeader';
import Button from '../../../components/bootstrap/Button';
import Page from '../../../layout/Page/Page';

import USERS from '../../../common/data/userDummyData';

import Avatar, { AvatarGroup } from '../../../components/Avatar';
import FormCreateMember from './FormCreateMember';
import FormProjectDescription from './FormProjectDescription';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import { AsyncSelect } from '../components/Select';
import DatePicker from '../components/DatePicker';
import Input from '../../../components/bootstrap/forms/Input';
import { getQueryParams, getRequester, cookieNameGenerator } from '../../../helpers/helpers';
import BoardModule from '../../../modules/bakti/BoardModule';
import BoardRoleModule from '../../../modules/jundi/BoardRoleModule';
import TaskModule from '../../../modules/bakti/TaskModule';
import PriorityModule from '../../../modules/bakti/PriorityModule';
import GeneralSettingModule from '../../../modules/GeneralSettingModule';
import LoadingOverlay from '../components/LoadingOverlay';
import FormCreateTask from './FormCreateTask';
import BoardMenu from './BoardMenu';
import './index.scss';
import useDarkMode from '../../../hooks/useDarkMode';
import TaskBoard from './TaskBoard';
import { capitalizeFirstLetter, writeToLocalStorage } from '../utils';
import Alert from '../../../components/bootstrap/Alert';
import { isBoardRoleAccess } from '../../../helpers/board-role';
import { loginRequest } from '../../../config/authConfig';
import AuthModule from '../../../modules/AuthModule';
import LoadingContent from '../components/LoadingContent';

const getWindowDimensions = () => {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height,
	};
};

const ButtonSyncOffice = ({ isAuthenticated, handleSyncOffice, windowDimensions }) => {
	return (
		<Button
			disabled={isAuthenticated}
			icon='CustomMicrosoft'
			type='button'
			color='light'
			className='bg-white'
			onClick={() =>
				windowDimensions?.width <= 760
					? handleSyncOffice('redirect')
					: handleSyncOffice('popup')
			}>
			{isAuthenticated ? 'Synced' : 'Sync Office365'}
		</Button>
	);
};

ButtonSyncOffice.propTypes = {
	isAuthenticated: PropTypes.bool,
	windowDimensions: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
	handleSyncOffice: PropTypes.func,
};
ButtonSyncOffice.defaultProps = {
	isAuthenticated: false,
	windowDimensions: {
		width: null,
		height: null,
	},
	handleSyncOffice: () => {},
};
const Index = () => {
	const { state } = useLocation();
	const [role, setRole] = useState(state?.role);

	const navigate = useNavigate();
	const { username, email } = getRequester();

	const { darkModeStatus } = useDarkMode();
	const { board_code: boardCode } = getQueryParams();

	const [loading, setLoading] = useState(false);
	const [loadingSubmit, setLoadingSubmit] = useState(false);

	const [detailBoard, setDetailBoard] = useState(null);

	const [currentUser, setCurrentUser] = useState(null);
	const [boardData, setBoardData] = useState([]);
	const [options, setOptions] = useState({
		loading: false,
		reload: true,
	});

	const [openMenu, setOpenMenu] = useState(false);
	const [openAddMember, setOpenAddMember] = useState(false);
	const [openProject, setOpenProject] = useState(false);
	const [openCreateTask, setOpenCreateTask] = useState(false);

	const memberRef = useRef(null);

	/* Office365 */
	const { instance, inProgress, accounts } = useMsal();
	const [cookieAcc, , removeCookie] = useCookie([cookieNameGenerator('ssoaccount')]);
	const isAuthenticated = useIsAuthenticated() ?? accounts[0]?.idTokenClaims?.preferred_username;
	const isLNKEmail =
		email?.toLowerCase().includes('lnk.co.id') && email.toLowerCase() !== 'lnk@lnk.co.id';
	const isEmailSSO = cookieAcc ? window.atob(cookieAcc) : null;
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const formikFilter = useFormik({
		initialValues: {
			member: null,
			date_start: null,
			date_end: null,
			search: '',
		},
		enableReinitialize: true,
		onReset: () => {
			memberRef.current?.clearValue();
			setOptions((e) => ({ ...e, reload: true }));
		},
		onSubmit: () => {
			setOptions((e) => ({ ...e, reload: true }));
		},
	});
	const getToken = () => {
		if (
			inProgress === InteractionStatus.None &&
			(isEmailSSO || isLNKEmail) &&
			isAuthenticated
		) {
			const accessTokenRequest = {
				...loginRequest,
				account: accounts[0],
			};

			return instance
				.acquireTokenSilent(accessTokenRequest)
				.then((accessTokenResponse) => {
					// Acquire token silent success
					const { accessToken } = accessTokenResponse;
					return accessToken;
				})
				.catch((error) => {
					if (error instanceof InteractionRequiredAuthError) {
						instance
							.acquireTokenPopup(accessTokenRequest)
							.then(function (accessTokenResponse) {
								// Acquire token interactive success
								const { accessToken } = accessTokenResponse;
								return accessToken;
							})
							// eslint-disable-next-line no-unused-vars
							.catch(function (err) {
								// Acquire token interactive failure

								Swal.fire({
									heightAuto: false,
									title: 'Warning!',
									text: 'Please login outlook first!',
									icon: 'info',
								});
								return { error: true, message: 'Please login outlook first!' };
							});
					}

					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: 'Please login outlook first!',
						icon: 'info',
					});
					return { error: true, message: 'Please login outlook first!' };
				});
		}
		return { error: true };
	};
	const clearMSALToken = () => {
		Swal.fire({
			icon: 'error',
			text: 'Please log in  with your account properly!',
			showDenyButton: true,
			showCancelButton: false,
			confirmButtonText: 'Relogin Apps',
			denyButtonText: `Relogin Outlook`,
		}).then((result) => {
			/* Read more about isConfirmed, isDenied below */
			if (result.isConfirmed) {
				AuthModule.logout(removeCookie);
			} else if (result.isDenied) {
				instance.logoutRedirect({
					postLogoutRedirectUri: '/logout',
				});
			}
		});
	};
	useEffect(() => {
		if (isAuthenticated) {
			if (
				email.toLowerCase() !==
				accounts[0]?.idTokenClaims?.preferred_username.toLocaleLowerCase()
			) {
				return clearMSALToken();
			}
		}

		if (
			// uda login sso tapi belum login email di sini
			(!isAuthenticated && isEmailSSO) ||
			// email lnk dan belum login email dan accounts e kosong
			(isLNKEmail && !isAuthenticated && accounts.length === 0)
		) {
			// login
			if (windowDimensions.width <= 760) {
				return handleSyncOffice('redirect');
			}
			return handleSyncOffice('popup');
		}
		return {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [instance, accounts, inProgress, cookieAcc, isAuthenticated, isLNKEmail]);

	// load setting default
	const fetchDefault = async () => {
		writeToLocalStorage('board_code', '');
		writeToLocalStorage('board_title', '');
		writeToLocalStorage('board_user', '');
		writeToLocalStorage('board_members', []);
		writeToLocalStorage('board_column', []);
		writeToLocalStorage('board_key', '');
		writeToLocalStorage('task_attachment', {});
		writeToLocalStorage('board_priority', []);

		BoardModule.findOneByBoardCode(boardCode)
			.then((response) => {
				const listMembers = [...response.members].map((user) => ({
					username: user?.username,
					name: user?.name,
					email: user?.email,
					role: user?.role,
					value: user?.username,
					label: `${user?.username} - ${user?.name}`,
					created_at: user?.created_at,
					isWaitingApproval: user?.isWaitingApproval,
				}));
				setDetailBoard({ ...response, members: listMembers });

				writeToLocalStorage('board_code', response?.trans_code);
				writeToLocalStorage('board_title', response?.title);
				writeToLocalStorage('board_members', listMembers);
				writeToLocalStorage('board_column', response?.board_column || []);
				writeToLocalStorage('board_key', response?.board_key);

				const findMember = listMembers.find((e) => e.username === username);
				writeToLocalStorage('board_user', findMember);
				setCurrentUser(findMember);

				// get rules
				BoardModule.getRules({ actor: JSON.stringify(findMember) })
					.then((res) => {
						writeToLocalStorage('board_rules', res?.rules);
					})
					.catch(() => {})
					.finally(() => {});
			})
			.catch(() => {})
			.finally(() => {});

		// form-task-attachment
		GeneralSettingModule.getFileTypeAndLimit({ type: 'form-task-attachment' })
			.then((response) => {
				writeToLocalStorage('task_attachment', response);
			})
			.catch(() => {})
			.finally(() => {});

		// load priority
		PriorityModule.read({ showAll: true })
			.then((response) => {
				writeToLocalStorage('board_priority', response.foundData);
			})
			.catch(() => {})
			.finally(() => {});
	};

	// load data board
	const fetchData = async () => {
		setOptions((e) => ({ ...e, loading: true }));

		setLoading(true);

		const query = {};

		if (formikFilter.values.date_start) {
			query.date_start = formikFilter.values.date_start;
		}

		if (formikFilter.values.date_end) {
			query.date_end = formikFilter.values.date_end;
		}

		if (formikFilter.values.search) {
			query.search = formikFilter.values.search;
		}

		TaskModule.findAllByBoardCode(boardCode, query)
			.then((respponse) => {
				setBoardData(respponse);
			})
			.catch(() => {
				setBoardData({});
			})
			.finally(() => {
				setLoading(false);
				setOptions((e) => ({ ...e, loading: false, reload: false }));
			});
	};

	const fetchRole = async () => {
		if (role) return;
		BoardRoleModule.getRole({ boardCode }).then(({ data }) => {
			setRole(data.role);
		});
	};

	useEffect(() => {
		if (options.reload && boardCode) {
			Promise.all([fetchDefault(), fetchData(), fetchRole()]);
		} else if (options.reload && !boardCode) {
			navigate('/board/workflow');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.reload, boardCode]);

	const handleSyncOffice = (loginType) => {
		if (loginType === 'popup') {
			return instance.loginPopup(loginRequest);
		}
		return instance.loginRedirect(loginRequest);
	};
	return (
		<PageWrapper title='Board'>
			<PageLayoutHeader />

			<SubHeader>
				<SubHeaderLeft>
					<Button
						color='info'
						isLink
						icon='ArrowBack'
						onClick={() =>
							navigate({ pathname: '/board/workflow' }, { replace: true })
						}>
						Back
					</Button>
					<SubheaderSeparator />
					<span className='fw-bold'>{detailBoard?.title}</span>
					{detailBoard && (
						<Button
							isLight
							icon='EditNote'
							color='success'
							onClick={() => setOpenProject(!openProject)}>
							Project Description
						</Button>
					)}
					{detailBoard &&
						isBoardRoleAccess(currentUser?.role, ['owner', 'member']) &&
						!detailBoard?.is_closed && (
							<Button
								isLight
								icon='CalendarToday'
								color='info'
								onClick={() =>
									navigate(`/schedule/${boardCode}`, {
										state: {
											role,
											detailBoard,
										},
									})
								}>
								Schedule
							</Button>
						)}
					{inProgress === InteractionStatus.None &&
						(isEmailSSO || isLNKEmail) &&
						detailBoard?.visibility === 'public' && (
							<div className='col-auto d-flex'>
								<ButtonSyncOffice
									handleSyncOffice={handleSyncOffice}
									windowDimensions={windowDimensions}
									isAuthenticated={isAuthenticated}
								/>
							</div>
						)}
				</SubHeaderLeft>
				<SubHeaderRight>
					{detailBoard && (
						<span className='me-4'>{capitalizeFirstLetter(currentUser?.role)}</span>
					)}
					{detailBoard && detailBoard?.members?.length > 0 && (
						<AvatarGroup>
							{detailBoard?.members?.map((member, index) => (
								<Avatar
									key={'user'.concat(index)}
									src={USERS.JOHN.src}
									srcSet={USERS.JOHN.srcSet}
									color={USERS.JOHN.color}
									userName={member.name}
								/>
							))}
						</AvatarGroup>
					)}

					{isBoardRoleAccess(currentUser?.role, ['owner', 'member'])
						? detailBoard &&
						  !detailBoard?.is_closed && (
								<Button
									isLight
									icon='PersonAdd'
									color='primary'
									className='rounded-circle'
									onClick={() => setOpenAddMember(!openAddMember)}
								/>
						  )
						: null}

					{detailBoard && (
						<Button
							isLight
							icon='MoreHoriz'
							color='primary'
							className='rounded-circle'
							onClick={() => setOpenMenu(true)}
						/>
					)}
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid'>
				<div className='row py-1'>
					<div className='col-sm-12 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='member' label='Member'>
							<AsyncSelect
								ref={memberRef}
								placeholder='All'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formikFilter.values.member}
								value={formikFilter.values.member}
								onChange={(e) => formikFilter.setFieldValue('member', e)}
								isClearable
							/>
						</FormGroup>
					</div>
					<div className='col-sm-6 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='date_start' label='Due Date (Start)'>
							<DatePicker
								placeholder='YYYY-MM-DD'
								dateFormat='yyyy-MM-dd'
								maxDate={formikFilter.values.date_end}
								value={formikFilter.values.date_start}
								onChange={(value) =>
									formikFilter.setFieldValue('date_start', value)
								}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-6 col-lg-4 col-xl-2 py-1'>
						<FormGroup id='date_end' label='Due Date (End)'>
							<DatePicker
								placeholder='YYYY-MM-DD'
								dateFormat='yyyy-MM-dd'
								minDate={formikFilter.values.date_start}
								value={formikFilter.values.date_end}
								onChange={(value) => formikFilter.setFieldValue('date_end', value)}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-12 col-lg-6 col-xl-2 py-1 blank-space'>
						<FormGroup id='search'>
							<Input
								placeholder='Search by Task'
								autoComplete='off'
								onChange={formikFilter.handleChange}
								onBlur={formikFilter.handleBlur}
								value={formikFilter.values.search}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										formikFilter.handleSubmit(e);
									}
								}}
							/>
						</FormGroup>
					</div>
					<div className='col-sm-12 col-lg-6 col-xl-4 py-1 blank-space'>
						<Button
							isLight={darkModeStatus}
							icon='Search'
							color='primary'
							type='button'
							onClick={formikFilter.handleSubmit}
							className='mx-1'>
							Filter
						</Button>

						<Button
							isLight={darkModeStatus}
							icon='Restore'
							color='warning'
							type='button'
							onClick={formikFilter.resetForm}
							className='mx-1'>
							Reset
						</Button>

						{isBoardRoleAccess(currentUser?.role, ['owner', 'member']) &&
							!detailBoard?.is_closed && (
								<Button
									isLight={darkModeStatus}
									icon='Add'
									color='info'
									type='button'
									onClick={() => setOpenCreateTask(!openCreateTask)}
									className='mx-1 float-end'>
									Create Task
								</Button>
							)}
					</div>
				</div>

				{detailBoard && !loading && !detailBoard?.is_closed && (
					<div className='board row mx-n4 pb-3 px-3'>
						<TaskBoard
							data={boardData}
							setData={setBoardData}
							onReload={() => {
								setTimeout(
									() => setOptions((prev) => ({ ...prev, reload: true })),
									300,
								);
							}}
							role={currentUser?.role || ''}
							getToken={getToken}
							detailBoard={detailBoard}
							isAuthenticated={isAuthenticated}
						/>
					</div>
				)}

				{detailBoard && !loading && detailBoard?.is_closed && (
					<div className='d-flex w-100 h-25 justify-content-center align-items-center'>
						<Alert color='info' isLight icon='info' className='w-25'>
							Board is Closed
						</Alert>
					</div>
				)}

				{!detailBoard && !loading && boardData.length == 0 && (
					<div className='d-flex w-100 h-25 justify-content-center align-items-center'>
						<Alert color='info' isLight icon='info' className='w-25'>
							No Data Found
						</Alert>
					</div>
				)}

				{loading && <LoadingContent onlyContent />}
				<LoadingOverlay loading={loadingSubmit} />
			</Page>

			<BoardMenu
				setIsOpen={setOpenMenu}
				isOpen={openMenu}
				data={detailBoard}
				onReload={() => {
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300);
				}}
				role={currentUser?.role || ''}
				setLoading={setLoadingSubmit}
			/>
			<FormCreateMember
				setIsOpen={setOpenAddMember}
				isOpen={openAddMember}
				onSuccess={() => {
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300);
				}}
				setLoading={setLoadingSubmit}
			/>

			<FormProjectDescription
				setIsOpen={setOpenProject}
				isOpen={openProject}
				boardCode={boardCode}
				role={currentUser?.role || ''}
				isReadOnly={detailBoard?.is_closed}
			/>

			<FormCreateTask
				isOpen={openCreateTask}
				setIsOpen={setOpenCreateTask}
				title='Create Task'
				onSuccess={() => {
					setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300);
				}}
				role={role}
				getToken={getToken}
				detailBoard={detailBoard}
				isAuthenticated={isAuthenticated}
			/>
		</PageWrapper>
	);
};

Index.propTypes = {};
Index.defaultProps = {};

export default Index;
