import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Accordion, { AccordionItem } from '../../../components/bootstrap/Accordion';
import Button from '../../../components/bootstrap/Button';
import BoardModule from '../../../modules/bakti/BoardModule';
import QuillComponent from '../components/QuillComponent';
import Nav, { NavItem } from '../../../components/bootstrap/Nav';
import Card, {
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Actions from './Actions';
import { cleanHTML, readFromLocalStorage } from '../utils';
import { isBoardRoleAccess } from '../../../helpers/board-role';
import { editBoardInformation } from '../../../enums/activityBoard';

const FormProjectDescription = ({
	isOpen,
	setIsOpen,
	title,
	size,
	id,
	boardCode,
	role,
	isReadOnly,
}) => {
	const currentUser = readFromLocalStorage('board_user');

	const [viewEdit, setViewEdit] = useState(false);
	const [reload, setReload] = useState(false);

	const [tabs] = useState([
		{
			id: 'ExecutiveSummary',
			icon: 'ListAlt',
			title: 'Executive Summary',
			field: 'executive_summary',
		},
		{
			id: 'ProjectGoals',
			icon: 'CheckCircleOutline',
			title: 'Project Goals',
			field: 'project_goals',
		},
		{ id: 'Deliverable', icon: 'EditNote', title: 'Deliverable', field: 'deliverable' },
		{ id: 'BusinessCase', icon: 'WorkOutline', title: 'Business Case', field: 'business_case' },
		{ id: 'Benefits', icon: 'Assignment', title: 'Benefits', field: 'benefits' },
		{ id: 'Scope', icon: 'NoteAdd', title: 'Scope', field: 'scope' },
		{
			id: 'MeasuringSuccess',
			icon: 'AssignmentTurnedIn',
			title: 'Measuring Success',
			field: 'measuring_success',
		},
	]);

	const [tab, setTab] = useState(tabs[0]);

	const initVal = {
		executive_summary: '',
		project_goals: '',
		deliverable: '',
		business_case: '',
		benefits: '',
		scope: '',
		measuring_success: '',
	};

	const formik = useFormik({
		initialValues: initVal,
		enableReinitialize: true,
		onReset: () => {
			setIsOpen(false);
			setViewEdit(false);
		},
		onSubmit: (values) => {
			const activity_type = editBoardInformation.activityType;
			const activity_detail = editBoardInformation.generateActivityDetail({
				username: currentUser.name,
			});
			const newValue = {
				description: JSON.stringify(values),

				// default
				actor: JSON.stringify(currentUser),
				activity_type,
				activity_detail,
			};

			Actions.updateBoard(boardCode, newValue)
				.then((response) => {
					formik.setValues(response?.description);
					setViewEdit(false);
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to save description!',
						icon: 'error',
					}).then((result) => {
						if (result.isConfirmed) {
							setReload(true);
							setViewEdit(false);
						}
					});
				})
				.finally(() => {});
		},
	});

	useEffect(() => {
		const fetch = async () => {
			try {
				BoardModule.findDescriptionByBoardCode(boardCode)
					.then((response) => {
						formik.setValues(response?.descriptions || initVal);
					})
					.catch(() => {})
					.finally(() => {});
			} catch (err) {
				//
			}
		};

		if (isOpen && boardCode) fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen, reload]);

	return (
		<Modal
			isOpen={isOpen}
			setIsOpen={formik.resetForm}
			titleId={id}
			size={size}
			isFocus={false}
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.resetForm}>
				<ModalTitle id={id}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				{viewEdit && !isReadOnly && (
					<div className='py-2'>
						<Nav design='tabs'>
							{tabs.map((item) => (
								<NavItem
									key={item.id}
									id={'navItem'.concat(item.id)}
									isActive={tab.id === item.id}
									onClick={() => setTab(item)}>
									<div>{item.title}</div>
								</NavItem>
							))}
						</Nav>

						<div className='mb-2' />

						<Card shadow='sm'>
							<CardHeader borderSize={1} size='sm'>
								<CardLabel icon={tab.icon} iconColor='info'>
									<CardTitle>{tab.title}</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<QuillComponent
									value={formik.values[tab.field]}
									onChange={(e) => formik.setFieldValue(tab.field, e)}
									// textOnly
								/>
							</CardBody>
						</Card>
					</div>
				)}

				{(!viewEdit || isReadOnly) && (
					<div className='py-2'>
						{tabs.map((item) => (
							<Accordion
								key={item.id}
								id={item.id}
								activeItemId={false}
								isMultiple
								shadow='sm'
								className='py-1'>
								<AccordionItem
									id={item.id}
									title={item.title}
									icon={item.icon}
									overWriteColor='info'>
									<QuillComponent
										value={cleanHTML(formik.values[item.field])}
										// textOnly
										readOnly
									/>
								</AccordionItem>
							</Accordion>
						))}
					</div>
				)}

				<div className='py-2'>
					{isBoardRoleAccess(role, ['owner', 'member'])
						? !viewEdit &&
						  !isReadOnly && (
								<Button
									icon='Edit'
									color='info'
									className='float-start'
									onClick={() => setViewEdit(!viewEdit)}>
									Edit
								</Button>
						  )
						: null}

					{viewEdit && (
						<Button
							icon='Save'
							color='info'
							className='float-end'
							onClick={formik.handleSubmit}>
							Save
						</Button>
					)}

					<Button isLink color='info' className='float-end' onClick={formik.resetForm}>
						Close
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

FormProjectDescription.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	boardCode: PropTypes.string,
	role: PropTypes.string,
	isReadOnly: PropTypes.bool,
};
FormProjectDescription.defaultProps = {
	id: 'formProject',
	title: 'Project Description',
	size: 'xl',
	boardCode: null,
	role: null,
	isReadOnly: false,
};

export default FormProjectDescription;
