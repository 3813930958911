import jwt_decode from 'jwt-decode';

export function test() {
	return null;
}

export const cookieNameGenerator = (name) => {
	if (process.env.REACT_APP_ENV === 'production') {
		return name;
	}
	return `${process.env.REACT_APP_ENV}-${name}`;
};
export const checkTokenExpiration = (token) => {
	if (!token) {
		return { error: true, message: 'Expired Token' };
	}

	try {
		const decoded = jwt_decode(token);
		const expTimestamp = decoded.exp;

		if (!expTimestamp) {
			// Handle missing 'exp' (optional)

			return { error: true, message: 'Expired Token' };
		}

		const currentTime = Math.floor(Date.now() / 1000);
		if (currentTime > expTimestamp) {
			return { error: true, message: 'Expired Token' };
		}
		return { error: false, message: 'Token is Ok' };
	} catch (err) {
		return { error: true, message: 'Expired Token' };
	}
};

export const getRequester = () => {
	try {
		const acToken = JSON.parse(localStorage.getItem('accessToken'));
		const position = JSON.parse(localStorage.getItem('position'));
		const photo = JSON.parse(localStorage.getItem('photo'));
		const decodedToken = jwt_decode(acToken.accessToken);
		const { details, username } = decodedToken;

		const result = {
			username,
			email: details.hris_org_tree?.current_person?.email,
			department: position?.department_name,
			department_code: position?.department_code,
			person_name: details.hris_org_tree?.current_person?.person_name,
			position_name: position?.position_name,
			position_code: position?.position_code,
			position,
			photo,
			position_level: position?.position_level_grade,
		};

		if (details?.version) {
			result.version = details.version;
		}

		return result;
	} catch (e) {
		return {};
	}
};

export const getRouting = (data) => {
	const rawRoute = Object.keys(data)
		.map((item) => {
			if (data[item].subMenu) {
				return Object.keys(data[item].subMenu).map((sb) => {
					return {
						path: data[item].subMenu[sb].path,
						element: data[item].subMenu[sb].element,
					};
				});
			}
			return null;
		})
		.filter((e) => e);
	const merge = [].concat(...rawRoute);
	return merge;
};
export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	document.documentElement.setAttribute('os', os);
	return os;
}

export const getColumnName = (value) => {
	const ordA = 'A'.charCodeAt(0);
	const ordZ = 'Z'.charCodeAt(0);
	const len = ordZ - ordA + 1;

	let s = '';
	while (value >= 0) {
		s = String.fromCharCode((value % len) + ordA) + s;
		value = Math.floor(value / len) - 1;
	}

	return s;
};

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs) => {
	return (value) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];
	const array = new Uint32Array(10);
	// eslint-disable-next-line no-restricted-globals
	const { crypto } = self;

	const color = Math.floor(crypto.getRandomValues(array) * colors.length);

	return colors[color];
};

export const priceFormat = (price) => {
	return price.toLocaleString('en-US', {
		style: 'currency',
		currency: 'USD',
	});
};

export const average = (array) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1, value2) => ((value1 / value2 - 1) * 100).toFixed(2);

export const getFirstLetter = (text, letterCount = 2) =>
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func, wait = 1000) => {
	let timeout;

	return function executedFunction(...args) {
		const later = () => {
			clearTimeout(timeout);
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};

export const convertToRupiah = (angka) => {
	return new Intl.NumberFormat('id-ID', {
		// style: 'currency',
		currency: 'IDR',
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
		.format(angka)
		.replace(/\D00(?=\D*$)/, '');
};

export const convertToAngka = (rupiah) => {
	return parseInt(rupiah.replace(/,.*|[^0-9]/g, ''), 10);
};

export const saveAs = (buffer, type, filename) => {
	const blob = new Blob([buffer], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = url;
	a.download = filename;
	a.click();
	window.URL.revokeObjectURL(url);
};
export const convertToTextNumber = (angka) => {
	return new Intl.NumberFormat('id-ID', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(angka);
};

/**
 *
 * @param {String} query
 * @returns
 */
export const getQueryParams = (query = window.location.search) => {
	const params = new URLSearchParams(query);
	const result = {};

	params.forEach((value, key) => {
		if (result[key]) {
			// If the key already exists, convert the value into an array or add to the existing array
			result[key] = Array.isArray(result[key])
				? [...result[key], value]
				: [result[key], value];
		} else {
			// If the key does not exist, add it to the result
			result[key] = value;
		}
	});

	return result;
};

export const getListRoles = () => {
	const roles = JSON.parse(localStorage.getItem('list_roles'));
	const selected_roles = JSON.parse(localStorage.getItem('roles'));
	const selected_position = JSON.parse(localStorage.getItem('position'));
	if (roles && roles?.length > 0) {
		return {
			roles: roles.map((r) => ({
				...r,
				is_selected:
					r.role_name === selected_roles &&
					r.position_code === selected_position.position_code,
			})),
		};
	}
	return [];
};
