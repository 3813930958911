const moment = require('moment-timezone');

// Helper function to format date
const formatDate = (date) => moment(date).format('DD MMM YYYY').tz('Asia/Jakarta');

// Factory function to create activities (HOF - Higher Order Function)
const createActivity = (activityType, generateActivityDetail) => ({
	activityType,
	generateActivityDetail,
});

// Define activities using functional approach
const activityBoard = {
	createBoard: createActivity(
		'Create Board',
		({ username, boardName }) => `${username} created ${boardName}`,
	),
	editBoardInformation: createActivity(
		'Edit Board Information',
		({ username }) => `${username} edited board information`,
	),
	editDescription: createActivity(
		'Edit Board Description',
		({ username }) => `${username} edited board description`,
	),
	closeBoard: createActivity(
		'Close Board',
		({ username, boardName }) => `${username} closed board ${boardName}`,
	),
	leaveBoard: createActivity(
		'Leave Board',
		({ username, boardName }) => `${username} left board ${boardName}`,
	),
	addMember: createActivity(
		'Add Member',
		({ username, addedUsername }) => `${username} added member ${addedUsername}`,
	),
	removeMember: createActivity(
		'Remove Member',
		({ username, removedUsername }) => `${username} removed member ${removedUsername}`,
	),
	addSchedule: createActivity(
		'Add Schedule',
		({ username, scheduleName }) => `${username} added schedule ${scheduleName}`,
	),
	editSchedule: createActivity(
		'Edit Schedule',
		({ username, scheduleName, editedDate }) =>
			`${username} edited schedule ${scheduleName} moved to ${formatDate(editedDate)}`,
	),
	deleteSchedule: createActivity(
		'Delete Schedule',
		({ username, scheduleName }) => `${username} deleted schedule ${scheduleName}`,
	),
	moveTaskToBoard: createActivity(
		'Move Task',
		({ username, taskName, boardName }) => `${username} moved task ${taskName} to ${boardName}`,
	),
};

module.exports = activityBoard;
