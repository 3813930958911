import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../components/bootstrap/Modal';
import Label from '../../../components/bootstrap/forms/Label';
import QuillComponent from '../components/QuillComponent';
import Dropzone from '../components/Dropzone';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Input from '../../../components/bootstrap/forms/Input';
import Select, { AsyncCreatableSelect, AsyncSelect } from '../components/Select';
import Button from '../../../components/bootstrap/Button';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import DatePicker from '../components/DatePicker';
import {
	cleanHTML,
	copyUrlToClipboard,
	FormatDate,
	ListAssigne,
	ListReporter,
	ListTags,
	openLink,
	readFromLocalStorage,
	TimeAgo,
} from '../utils';
import FormText from '../../../components/bootstrap/forms/FormText';
import { getQueryParams } from '../../../helpers/helpers';
import TaskModule from '../../../modules/bakti/TaskModule';
import LoadingContent from '../components/LoadingContent';
import Alert from '../../../components/bootstrap/Alert';
import { formatBytes } from '../../../helpers/format';
import BoardModule from '../../../modules/bakti/BoardModule';
import LoadingOverlay from '../components/LoadingOverlay';
import Actions from './Actions';
import FormModal from '../../afif/schedule/components/FormModal';
import {
	createFunction as createSchedule,
	editFunction as editSchedule,
	deleteFunction as deleteSchedule,
} from '../../afif/schedule/Schedule';
import DetailModal from '../../afif/schedule/components/DetailModal';
import Item from '../../afif/schedule/components/Item';
import ScheduleModule from '../../../modules/afif/ScheduleModule';
import { isBoardRoleAccess } from '../../../helpers/board-role';

import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import Checks from '../../../components/bootstrap/forms/Checks';
import {
	addReference,
	deleteReference,
	editReference,
	editTaskDescription,
	editTaskInformation,
	movedToTrashBin,
	moveTask,
	addComment,
	editComment,
	deleteComment,
} from '../../../enums/activityTask';
import { moveTaskToBoard } from '../../../enums/activityBoard';

const AddCommentComponent = ({ taskCode, actor, onReload }) => {
	const [isFocus, setIsFocus] = useState(false);
	const [value, setValue] = useState('');

	const handleSave = () => {
		try {
			const newValue = {
				content: value,
				actor: JSON.stringify(actor),

				activity_type: addComment.activityType,
				activity_detail: addComment.generateActivityDetail({
					username: actor.name,
				}),
			};

			Actions.createComment(taskCode, newValue)
				.then(() => {
					setIsFocus(false);
					setValue('');
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to save comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to save comment!',
				icon: 'error',
			});
		}
	};

	const handleCancel = () => {
		setIsFocus(false);
		setValue('');
	};

	return (
		<div className='py-2 d-flex'>
			<div className='me-2'>
				<Avatar
					src={USERS.JOHN.src}
					srcSet={USERS.JOHN.srcSet}
					color={USERS.JOHN.color}
					size={36}
				/>
			</div>
			<div className='ms-2 flex-grow-1'>
				<QuillComponent
					useFocus
					value={value}
					onChange={setValue}
					isFocus={isFocus}
					setIsFocus={setIsFocus}
					textOnly
				/>

				{isFocus && (
					<div className='pt-2'>
						<Button
							color='info'
							type='button'
							size='sm'
							onClick={handleSave}
							className='mx-1'>
							Save
						</Button>

						<Button
							isLink
							color='info'
							type='button'
							size='sm'
							onClick={handleCancel}
							className='mx-1'>
							Cancel
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

AddCommentComponent.propTypes = {
	taskCode: PropTypes.string,
	actor: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
};
AddCommentComponent.defaultProps = {
	taskCode: null,
	actor: null,
	onReload: () => {},
};

const CommentComponent = ({ data, useFocus, taskCode, actor, onReload, role }) => {
	const [isEditable, setIsEditable] = useState(false);
	const [value, setValue] = useState(data.content);

	const handleSave = () => {
		try {
			const newValue = {
				content: value,
				actor: JSON.stringify(actor),

				activity_type: editComment.activityType,
				activity_detail: editComment.generateActivityDetail({
					username: actor.name,
				}),
			};

			Actions.updateComment(data?._id, taskCode, newValue)
				.then(() => {
					setValue('');
					setIsEditable(false);
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to update comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to update comment!',
				icon: 'error',
			});
		}
	};

	const handleCancel = () => {
		setIsEditable(false);
	};

	const handleEdit = () => {
		setIsEditable(true);
	};

	const handleDelete = () => {
		try {
			const newValue = {
				actor: JSON.stringify(actor),
				activity_type: deleteComment.activityType,
				activity_detail: deleteComment.generateActivityDetail({
					username: actor.name,
				}),
			};

			Actions.deleteComment(data?._id, taskCode, newValue)
				.then(() => {
					setValue('');
					setIsEditable(false);
					onReload();
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Information!',
						text: 'Failed to delete comment!',
						icon: 'error',
					});
				})
				.finally(() => {});
		} catch (err) {
			Swal.fire({
				heightAuto: false,
				title: 'Information!',
				text: 'Failed to delete comment!',
				icon: 'error',
			});
		}
	};

	return (
		<div className='py-2 d-flex'>
			<div className='me-2'>
				<Avatar
					src={USERS.JOHN.src}
					srcSet={USERS.JOHN.srcSet}
					color={USERS.JOHN.color}
					size={36}
				/>
			</div>
			<div className='ms-2 row'>
				{!isEditable && (
					<div className='py-1 col-sm-12 d-flex align-items-center'>
						<div className='me-1 fw-bold'>{data?.actor?.name}</div>
						<div className='ms-1 form-text'>{TimeAgo(data?.created_at)}</div>
					</div>
				)}

				<div className='py-1 col-sm-12 d-flex'>
					{/* edit */}
					{isEditable && (
						<div className='py-1 flex-grow-1'>
							<QuillComponent
								useFocus={useFocus}
								value={value}
								onChange={setValue}
								textOnly
							/>
						</div>
					)}

					{/* view */}
					{!isEditable && <div dangerouslySetInnerHTML={{ __html: cleanHTML(value) }} />}
				</div>

				<div className='py-1 col-sm-12'>
					{isEditable && (
						<div className='pt-2'>
							<Button
								color='info'
								type='button'
								size='sm'
								onClick={handleSave}
								className='mx-1'>
								Save
							</Button>

							<Button
								isLink
								color='info'
								type='button'
								size='sm'
								onClick={handleCancel}
								className='mx-1'>
								Cancel
							</Button>
						</div>
					)}

					{isBoardRoleAccess(role, ['owner', 'member'])
						? !isEditable && (
								<div className='pt-2'>
									<Button
										isLink
										color='info'
										type='button'
										size='sm'
										onClick={handleEdit}
										className='mx-1'>
										Edit
									</Button>

									<Button
										isLink
										color='danger'
										type='button'
										size='sm'
										onClick={handleDelete}
										className='mx-1'>
										Delete
									</Button>
								</div>
						  )
						: null}
				</div>
			</div>
		</div>
	);
};

CommentComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	useFocus: PropTypes.bool,
	taskCode: PropTypes.string,
	actor: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
	role: PropTypes.string,
};
CommentComponent.defaultProps = {
	data: null,
	useFocus: false,
	taskCode: null,
	actor: null,
	onReload: () => {},
	role: null,
};

const ActivitiesComponent = ({ role }) => {
	const { task_code: taskCode } = getQueryParams();
	const currentUser = readFromLocalStorage('board_user');

	const [data, setData] = useState([]);
	const [options, setOptions] = useState({ loading: false, reload: true });

	useEffect(() => {
		const fetch = async () => {
			if (role === 'guest') return;

			try {
				setOptions((prev) => ({ ...prev, loading: true }));

				TaskModule.findComment(taskCode, { perPage: 10 })
					.then((res) => {
						setData(res?.foundData || []);
					})
					.catch(() => {
						setData([]);
					})
					.finally(() => {
						setOptions((prev) => ({ ...prev, reload: false, loading: false }));
					});
			} catch (err) {
				//
			}
		};

		if (options.reload && taskCode) fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [options.reload]);

	const addCommentPlaceholder = () => {
		return (
			<div className='py-2 d-flex'>
				<div className='me-2'>
					<Avatar
						src={USERS.JOHN.src}
						srcSet={USERS.JOHN.srcSet}
						color={USERS.JOHN.color}
						size={36}
					/>
				</div>
				<div className='ms-2 flex-grow-1'>
					<Input type='text' placeholder='Write a comment...' readOnly />
				</div>
			</div>
		);
	};

	if (!isBoardRoleAccess(role, ['owner', 'member', 'spectator'])) {
		return addCommentPlaceholder();
	}

	return (
		<div>
			{isBoardRoleAccess(role, ['spectator']) ? (
				addCommentPlaceholder()
			) : (
				<AddCommentComponent
					taskCode={taskCode}
					actor={currentUser}
					onReload={() =>
						setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300)
					}
				/>
			)}

			<div className='border-bottom my-3' />

			{options.loading && <LoadingContent onlyContent />}

			{/* show comment 10 last created */}
			{!options.loading &&
				data.map((item, index) => (
					<CommentComponent
						key={'comment'.concat(index)}
						taskCode={taskCode}
						actor={currentUser}
						data={item}
						onReload={() =>
							setTimeout(() => setOptions((prev) => ({ ...prev, reload: true })), 300)
						}
						role={role}
					/>
				))}
		</div>
	);
};

ActivitiesComponent.propTypes = {
	role: PropTypes.string,
};
ActivitiesComponent.defaultProps = {
	role: null,
};

const LinkComponent = ({ value, onChange, onDelete, isEdit, isNew, role }) => {
	const [showEdit, setShowEdit] = useState(isEdit);

	const [data, setData] = useState(value);

	const handleSave = () => {
		onChange({ link: data, isEdit: false, isNew: false });
		setShowEdit(!showEdit);
	};

	const handleCancel = () => {
		if (isNew) {
			onDelete(true);
		} else {
			onChange({ link: value, isEdit: false, isNew: false }, true);
			setShowEdit(!showEdit);
		}
	};

	return (
		<div className='row py-1'>
			{!showEdit && (
				<div className='col-sm-12 d-flex'>
					<div className='flex-grow-1'>
						<Input value={value} readOnly />
					</div>
					<div>
						{isBoardRoleAccess(role, ['owner', 'member']) ? (
							<Button
								isOutline
								icon='Edit'
								type='button'
								color='info'
								size='sm'
								onClick={() => setShowEdit(!showEdit)}
								className='mx-1'
							/>
						) : null}

						{isBoardRoleAccess(role, ['owner', 'member']) ? (
							<Button
								isOutline
								icon='Delete'
								type='button'
								color='danger'
								size='sm'
								onClick={onDelete}
								className='mx-1'
							/>
						) : null}

						<Button
							isOutline
							icon='KeyboardArrowRight'
							type='button'
							color='success'
							size='sm'
							onClick={() => openLink(value)}
							className='mx-1'
						/>
					</div>
				</div>
			)}

			{showEdit && (
				<div className='col-sm-12 py-1'>
					<InputGroup>
						<InputGroupText>URL</InputGroupText>
						<Input value={data} onChange={(e) => setData(e.target.value.trim())} />
					</InputGroup>
				</div>
			)}

			{showEdit && (
				<div className='col-sm-12 py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						isDisable={!data.trim()}
						onClick={handleSave}
						className='mx-1'>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						onClick={handleCancel}
						className='mx-1'>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

LinkComponent.propTypes = {
	value: PropTypes.string,
	onChange: PropTypes.func,
	onDelete: PropTypes.func,
	isEdit: PropTypes.bool,
	isNew: PropTypes.bool,
	role: PropTypes.string,
};
LinkComponent.defaultProps = {
	value: '',
	onChange: () => '',
	onDelete: () => {},
	isEdit: true,
	isNew: true,
	role: null,
};

const ReferenceComponent = ({ data, onUpdate, role }) => {
	const currentUser = readFromLocalStorage('board_user');

	const [list, setList] = useState(data);

	const handleAdd = () => {
		setList([...list, { link: '', isEdit: true, isNew: true }]);
	};

	const handleChange = (index, value, isCancel) => {
		const newList = [...list];
		newList[index] = value;

		setList(newList);

		if (!isCancel) {
			const reference = newList.map((e) => e.link);
			const isEdit = newList.every((e) => !e.isNew);
			let activity_type = addReference.activityType;
			let activity_detail = addReference.generateActivityDetail({
				username: currentUser.name,
				link: reference.toString(),
			});
			if (isEdit) {
				activity_type = editReference.activityType;
				activity_detail = editReference.generateActivityDetail({
					username: currentUser.name,
					link: reference.toString(),
				});
			}
			onUpdate({
				reference: JSON.stringify(reference),

				// default
				actor: JSON.stringify(currentUser),
				activity_type,
				activity_detail,
			});
		}
	};

	const handleDelete = (index, isCancel) => {
		const newList = [...list];
		newList.splice(index, 1);

		setList(newList);

		if (!isCancel) {
			const reference = newList.map((e) => e.link);
			const activity_type = deleteReference.activityType;
			const activity_detail = deleteReference.generateActivityDetail({
				username: currentUser.name,
				link: reference.toString(),
			});
			onUpdate({
				reference: JSON.stringify(reference),

				// default
				actor: JSON.stringify(currentUser),
				activity_type,
				activity_detail,
			});
		}
	};

	return (
		<div>
			<div className='py-1'>
				<Label className='fw-bold'>Reference</Label>
			</div>

			{list.map((item, index) => (
				<LinkComponent
					key={'link-'.concat(index)}
					value={item.link}
					isEdit={item.isEdit}
					isNew={item.isNew}
					onChange={(value, isCancel) => handleChange(index, value, isCancel)}
					onDelete={(isCancel) => handleDelete(index, isCancel)}
					role={role}
				/>
			))}

			{isBoardRoleAccess(role, ['owner', 'member']) ? (
				<div className='py-1'>
					<Button
						isOutline
						icon='Add'
						color='info'
						type='button'
						onClick={handleAdd}
						className='mx-1'>
						Add Reference
					</Button>
				</div>
			) : null}
		</div>
	);
};

ReferenceComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.array]),
	onUpdate: PropTypes.func,
	role: PropTypes.string,
};
ReferenceComponent.defaultProps = {
	data: [],
	onUpdate: () => {},
	role: null,
};

const InformationComponent = ({ data, onUpdate, role }) => {
	const { board_code: boardCode } = getQueryParams();
	const boardKey = readFromLocalStorage('board_key');
	const boardColumn = readFromLocalStorage('board_column');
	const listPriority = readFromLocalStorage('board_priority');
	const currentUser = readFromLocalStorage('board_user');

	const [showEdit, setShowEdit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [optionTags, setOptionTags] = useState([]);

	const selectRef = useRef();

	useEffect(() => {
		const fetchTags = async () => {
			TaskModule.loadOptionsTags('', data?.board_code)
				.then((response) => {
					setOptionTags([...response]);
				})
				.catch(() => {
					setOptionTags([]);
				});
		};
		fetchTags();
	}, [data?.board_code]);

	const formik = useFormik({
		initialValues: {
			title: data?.title,
			priority: data?.priority,
			tags: data?.tags,
			status: data?.status,
			assignee: data?.assignee || [],
			reporter: data?.reporter,
			due_date: data?.duedate_at,
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			title: Yup.string().trim().required('Required'),
			priority: Yup.mixed().required('Required'),
			tags: Yup.array().optional(),
			status: Yup.mixed().required('Required'),
			assignee: Yup.array().optional(),
			reporter: Yup.object().nullable().required('Required'),
			due_date: Yup.date().nullable().optional(),
		}),
		onReset: () => {
			setShowEdit(false);
		},
		onSubmit: (v) => {
			try {
				const activity_type = editTaskInformation.activityType;
				const activity_detail = editTaskInformation.generateActivityDetail({
					username: currentUser.name,
					taskName: v.title,
				});
				const newValue = {
					title: v.title,
					priority: JSON.stringify(v.priority),
					tags: JSON.stringify(v.tags),
					status: JSON.stringify(v.status),
					assignee: JSON.stringify(v.assignee),
					reporter: JSON.stringify(v.reporter),
					duedate_at: v.due_date,

					// default
					actor: JSON.stringify(currentUser),
					activity_type,
					activity_detail,
				};

				setLoading(true);

				onUpdate(newValue, (err) => {
					if (err) {
						//
					} else {
						setShowEdit(false);
					}
					setLoading(false);
				});
			} catch (err) {
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Please check your entries again!',
					icon: 'error',
				});
			}
		},
	});

	return (
		<Card shadow='sm'>
			<CardHeader size='sm' borderSize={1}>
				<CardLabel icon='InfoOutline' iconColor='info'>
					<CardTitle>Detail Information</CardTitle>
				</CardLabel>
				{isBoardRoleAccess(role, ['owner', 'member'])
					? !showEdit && (
							<CardActions>
								<Button
									isOutline
									icon='Edit'
									type='button'
									size='sm'
									color='info'
									onClick={() => setShowEdit(true)}
								/>
							</CardActions>
					  )
					: null}
			</CardHeader>
			<CardBody>
				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Code</div>
					<div className='col-sm-9 col-lg-9'>{`${boardKey}-${data?.task_key}`}</div>
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Task Name</div>
					{!showEdit && <div className='col-sm-9 col-lg-9'>{formik.values.title}</div>}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Input
								name='title'
								autoComplete='off'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.title}
								isValid={formik.isValid}
								isTouched={formik.touched.title}
								invalidFeedback={formik.errors.title}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Priority </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{formik.values.priority?.label}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Select
								placeholder='Select one'
								options={listPriority}
								defaultValue={formik.values.priority}
								value={formik.values.priority}
								onChange={(e) => formik.setFieldValue('priority', e)}
								isValid={!formik.errors.priority}
								isSubmitted={!!formik.errors.priority}
								invalidFeedback={formik.errors.priority}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Tags </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{ListTags(formik.values.tags)}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncCreatableSelect
								ref={selectRef} // Adding the ref
								placeholder='Select Tags'
								loadOptions={(e) => TaskModule.loadOptionsTags(e, data.board_code)}
								cacheOptions
								defaultOptions={optionTags}
								defaultValue={formik.values.tags}
								value={formik.values.tags}
								onChange={(e) => formik.setFieldValue('tags', e)}
								isMulti
								isCreatable
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Status </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{formik.values.status?.label}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<Select
								placeholder='Select One'
								options={boardColumn}
								defaultValue={formik.values.status}
								value={formik.values.status}
								onChange={(e) => formik.setFieldValue('status', e)}
								isValid={!formik.errors.status}
								isSubmitted={!!formik.errors.status}
								invalidFeedback={formik.errors.status}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Assignee </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>
							{ListAssigne(formik.values.assignee)}
						</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncSelect
								placeholder='Type here..'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formik.values.assignee}
								value={formik.values.assignee}
								onChange={(e) => formik.setFieldValue('assignee', e)}
								isMulti
								isValid={!formik.errors.assignee}
								isSubmitted={!!formik.errors.assignee}
								invalidFeedback={formik.errors.assignee}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Reporter (Owner) </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>
							{ListReporter(formik.values.reporter)}
						</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<AsyncSelect
								placeholder='Type here..'
								loadOptions={() => BoardModule.loadOptionsMember(boardCode)}
								cacheOptions
								defaultOptions
								defaultValue={formik.values.reporter}
								value={formik.values.reporter}
								onChange={(e) => formik.setFieldValue('reporter', e)}
								isValid={!formik.errors.reporter}
								isSubmitted={!!formik.errors.reporter}
								invalidFeedback={formik.errors.reporter}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Due Date </div>
					{!showEdit && (
						<div className='col-sm-9 col-lg-9'>{FormatDate(data?.duedate_at)}</div>
					)}
					{showEdit && (
						<div className='col-sm-9 col-lg-9'>
							<DatePicker
								dateFormat='yyyy-MM-dd'
								placeholder='YYYY-MM-DD'
								minDate={new Date()}
								value={formik.values.due_date}
								onChange={(value) => formik.setFieldValue('due_date', value)}
								isSubmitted={!!formik.errors.due_date}
								invalidFeedback={formik.errors.due_date}
							/>
						</div>
					)}
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Created At</div>
					<div className='col-sm-9 col-lg-9'>{FormatDate(data?.created_at, true)}</div>
				</div>

				<div className='row py-1' style={{ minHeight: '45px' }}>
					<div className='col-sm-3 col-lg-3 fw-bold'>Updated At</div>
					<div className='col-sm-9 col-lg-9'>{TimeAgo(data?.updated_at)}</div>
				</div>

				{showEdit && (
					<div className='py-1 d-flex justify-content-end'>
						<Button
							isLink
							color='info'
							type='button'
							size='sm'
							onClick={formik.resetForm}
							className='mx-1'>
							Cancel
						</Button>

						<Button
							color='info'
							type='button'
							size='sm'
							onClick={formik.handleSubmit}
							className='mx-1'>
							Save
						</Button>
					</div>
				)}

				<LoadingOverlay loading={loading} />
			</CardBody>
		</Card>
	);
};

InformationComponent.propTypes = {
	data: PropTypes.oneOfType([PropTypes.object]),
	onUpdate: PropTypes.func,
	role: PropTypes.string,
};
InformationComponent.defaultProps = {
	data: null,
	onUpdate: () => {},
	role: null,
};

const DescriptionComponents = ({ value, label, onUpdate, role }) => {
	const [data, setData] = useState(value);
	const [showEdit, setShowEdit] = useState(false);
	const currentUser = readFromLocalStorage('board_user');

	const handleSave = () => {
		const activity_type = editTaskDescription.activityType;
		const activity_detail = editTaskDescription.generateActivityDetail({
			username: currentUser.name,
			taskName: data.title,
		});
		onUpdate(
			{
				description: data,
				activity_type,
				activity_detail,
			},
			(err) => {
				if (err) {
					setData(value);
				}
				setShowEdit(false);
			},
		);
	};

	const handleCancel = () => {
		setData(value);
		setShowEdit(false);
	};

	return (
		<div>
			<div className='d-flex align-items-center py-1'>
				<div className='flex-grow-1'>
					<Label className='fw-bold'>{label}</Label>
				</div>

				{isBoardRoleAccess(role, ['owner', 'member'])
					? !showEdit && (
							<div>
								<Button
									isOutline
									icon='Edit'
									color='info'
									type='button'
									size='sm'
									className='mx-1'
									onClick={() => setShowEdit(true)}
								/>
							</div>
					  )
					: null}
			</div>

			{!showEdit && (
				<div className='py-1'>
					<QuillComponent value={value} readOnly textOnly />
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<QuillComponent value={data} onChange={setData} textOnly />
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						style={{ zIndex: 1001, position: 'relative' }}
						onClick={handleSave}>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

DescriptionComponents.propTypes = {
	value: PropTypes.string,
	onUpdate: PropTypes.func,
	label: PropTypes.string,
	role: PropTypes.string,
};
DescriptionComponents.defaultProps = {
	value: '',
	onUpdate: () => {},
	label: 'Label',
	role: null,
};

const MoveTaskModal = ({
	id,
	isOpen,
	setIsOpen,
	size,
	title,
	listStatus,
	initValues,
	onUpdate,
}) => {
	const boardRef = useRef(null);

	const formik = useFormik({
		initialValues: {
			is_move: false,
			board: initValues?.board,
			status: initValues?.status,
		},
		enableReinitialize: true,
		validationSchema: Yup.object({
			board: Yup.object()
				.nullable()
				.when('is_move', {
					is: true,
					then: Yup.object().nullable().required('Required'),
					otherwise: Yup.object().nullable().optional(),
				}),
			status: Yup.object().nullable().required('Required'),
		}),
		onReset: () => {
			boardRef.current?.clearValue();
			setIsOpen(false);
		},
		onSubmit: (values, { resetForm }) => {
			try {
				Swal.fire({
					heightAuto: false,
					title: 'Are you sure?',
					text: `You won't be able to revert this!`,
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#00941b',
					confirmButtonText: 'Yes, Confirm',
					cancelButtonColor: '#d33',
					cancelButtonText: `No, Cancel`,
					reverseButtons: true,
				}).then((result) => {
					if (result.isConfirmed) {
						const currentUser = readFromLocalStorage('board_user');

						let activity_type = moveTaskToBoard.activityType;
						let activity_detail = moveTaskToBoard.generateActivityDetail({
							username: currentUser.name,
							taskName: formik.values.title,
							boardName: values.board.title,
						});
						if (!values?.is_move) {
							activity_type = moveTask.activityType;
							activity_detail = moveTask.generateActivityDetail({
								username: currentUser.name,
								taskName: formik.values.title,
							});
						}
						const newValue = {
							status: JSON.stringify(values.status),

							// default
							actor: JSON.stringify(currentUser),
							activity_type,
							activity_detail,
						};

						if (values.is_move) {
							newValue.board_code = values.board.value;
						}

						onUpdate(newValue, (err) => {
							if (err) {
								Swal.fire({
									heightAuto: false,
									title: 'Information!',
									text: 'Failed to save comment!',
									icon: 'error',
								});
							} else {
								resetForm();
							}
						});
					}
				});
			} catch (err) {
				Swal.fire({
					heightAuto: false,
					title: 'Information!',
					text: 'Failed to save comment!',
					icon: 'error',
				});
			}
		},
	});

	return (
		<Modal
			setIsOpen={formik.resetForm}
			isOpen={isOpen}
			titleId={id}
			size={size}
			isFocus={false}
			isCentered
			enableEscape={false}
			isStaticBackdrop>
			<ModalHeader setIsOpen={formik.resetForm}>
				<ModalTitle id={id}>{title}</ModalTitle>
			</ModalHeader>
			<ModalBody>
				<div className='row'>
					<div className='col-sm-12 py-1'>
						<Checks
							id='is_move'
							name='is_move'
							type='checkbox'
							label='Move to another board'
							checked={formik.values.is_move}
							onChange={formik.handleChange}
						/>
					</div>

					{formik.values.is_move && (
						<div className='col-sm-12 py-1'>
							<Label>Board</Label>
							<AsyncSelect
								ref={boardRef}
								placeholder='Select board'
								loadOptions={(e) => BoardModule.loadOptions(e)}
								defaultValue={formik.values.board}
								value={formik.values.board}
								onChange={(e) => formik.setFieldValue('board', e)}
								isValid={!formik.errors.board}
								isSubmitted={!!formik.errors.board}
								invalidFeedback={formik.errors.board}
							/>
						</div>
					)}

					<div className='col-sm-12 py-1'>
						<Label>Status</Label>
						<Select
							options={listStatus}
							defaultValue={formik.values.status}
							value={formik.values.status}
							onChange={(e) => formik.setFieldValue('status', e)}
							isValid={!formik.errors.status}
							isSubmitted={!!formik.errors.status}
							invalidFeedback={formik.errors.status}
						/>
					</div>
				</div>
				<div className='col-sm-12 py-1 d-flex justify-content-end'>
					<Button
						isLink
						color='info'
						type='button'
						onClick={formik.resetForm}
						className='mx-1'>
						Cancel
					</Button>

					<Button
						icon='Save'
						color='info'
						type='button'
						onClick={formik.handleSubmit}
						className='mx-1'>
						Save
					</Button>
				</div>
			</ModalBody>
		</Modal>
	);
};

MoveTaskModal.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	listStatus: PropTypes.oneOfType([PropTypes.array]),
	initValues: PropTypes.oneOfType([PropTypes.object]),
	onUpdate: PropTypes.func,
};
MoveTaskModal.defaultProps = {
	id: 'form-move-task',
	title: 'Move Task',
	size: null,
	listStatus: [],
	initValues: { trans_code: '', status: null },
	onUpdate: () => {},
};

const CreateScheduleModal = ({ isOpen, setIsOpen, boardCode, taskCode, getSchedule }) => {
	// const [isActionLoading, setIsActionLoading] = useState(false);
	const [formOptions, setFormOptions] = useState({
		initialValues: {},
		title: 'Form Modal',
		handleCustomSubmit: () => {},
	});

	const handleCreate = () => {
		setFormOptions(() => ({
			title: 'Create Schedule',
			initialValues: {
				title: '',
				date: '',
				is_all_day: false,
				time: {
					start: '',
					end: '',
				},
				repeat: null,
				reminder: [],
				assign_to: [],
				location: '',
				description: '',
			},
			handleCustomSubmit: (values, handleReset) => {
				// setIsActionLoading(true);
				createSchedule(values)
					.then(() => {
						handleReset();
						getSchedule();
					})
					.catch(() => {})
					.finally(() => {
						// setIsActionLoading(false);
					});
			},
		}));
	};

	useEffect(() => {
		if (formOptions.title !== 'Form Modal') setIsOpen(true);
	}, [formOptions, setIsOpen]);

	return (
		<>
			<Button
				isLight
				icon='CalendarToday'
				color='success'
				onClick={handleCreate}
				className='m-2'>
				Create Schedule
			</Button>
			<FormModal
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				type='task'
				title={formOptions.title}
				initialValues={formOptions.initialValues}
				handleCustomSubmit={formOptions.handleCustomSubmit}
				boardCode={boardCode}
				taskCode={taskCode}
			/>
		</>
	);
};
CreateScheduleModal.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	boardCode: PropTypes.string.isRequired,
	taskCode: PropTypes.string.isRequired,
	getSchedule: PropTypes.func,
};
CreateScheduleModal.defaultProps = {
	isOpen: false,
	setIsOpen: () => false,
	getSchedule: () => {},
};

const FormUpdateTask = ({
	isOpen,
	setIsOpen,
	title,
	size,
	id,
	onReload,
	role,
	getToken,
	detailBoard,
	isAuthenticated,
}) => {
	const { board_code: boardCode, task_code: taskCode } = getQueryParams();

	// fileTypeTaskAttachment
	const fileType = readFromLocalStorage('task_attachment');
	const currentUser = readFromLocalStorage('board_user');
	const boardTitle = readFromLocalStorage('board_title');
	const boardColumn = readFromLocalStorage('board_column');

	const extentions = fileType?.extentions?.join(', ')?.toUpperCase();
	const limit = formatBytes(fileType?.limit);

	const [dataTask, setDataTask] = useState(null);
	const [loading, setLoading] = useState(false);

	const [isChange, setIsChange] = useState(false);

	const [dataSchedule, setDataSchedule] = useState([]);
	const { inProgress } = useMsal();

	const [openFormSchedule, setOpenFormSchedule] = useState(false);
	const [formScheduleOptions, setFormScheduleOptions] = useState({
		initialValues: {},
		title: 'Form Modal',
		handleCustomSubmit: () => {},
	});

	const [selectedItemSchedule, setSelectedItemSchedule] = useState({});
	const [openDetailSchedule, setOpenDetailSchedule] = useState(false);

	//
	const [openMoveModal, setOpenMoveModal] = useState(false);
	const [bearerToken, setBearerToken] = useState(null);
	useEffect(() => {
		if (
			detailBoard?.visibility === 'public' &&
			isAuthenticated &&
			inProgress === InteractionStatus.None
		) {
			getToken().then((r) => {
				setBearerToken(r);
			});
		}
	}, [detailBoard, getToken, isAuthenticated, inProgress]);
	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);

				TaskModule.findOneByTaskCode(taskCode)
					.then((response) => {
						const listReference = [...response.reference].map((e) => ({
							link: e,
							isEdit: false,
							isNew: false,
						}));
						setDataTask({ ...response, reference: listReference });
					})
					.catch(() => {
						setDataTask(null);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				//
			}
		};

		if (isOpen) fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	const handleClose = () => {
		if (isChange) {
			setIsChange(false);
			onReload();
		}
		setIsOpen(false);
	};

	const handleUpdate = (payload, callback) => {
		const newPayload = { ...payload };

		if (detailBoard?.visibility === 'public' && bearerToken && isAuthenticated) {
			newPayload.bearer_token = bearerToken;
		}
		Actions.updateTask(taskCode, { ...newPayload, actor: JSON.stringify(currentUser) })
			.then((response) => {
				const listReference = [...response.data.reference].map((e) => ({
					link: e,
					isEdit: false,
					isNew: false,
				}));
				const newData = { ...response.data, reference: listReference };
				setDataTask(newData);
				setIsChange(true);
				callback(null);
			})
			.catch((err) => {
				callback(err);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleTrash = () => {
		Swal.fire({
			heightAuto: false,
			title: 'Are you sure?',
			text: `You won't be able to revert this!`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#00941b',
			confirmButtonText: 'Yes, Confirm',
			cancelButtonColor: '#d33',
			cancelButtonText: `No, Cancel`,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const activity_type = movedToTrashBin.activityType;
				const activity_detail = movedToTrashBin.generateActivityDetail({
					username: currentUser.name,
					taskName: title,
				});
				Actions.deleteTask(taskCode, {
					// default
					actor: JSON.stringify(currentUser),
					activity_type,
					activity_detail,
				})
					.then(() => {
						Swal.fire({
							heightAuto: false,
							title: 'Success!',
							text: 'Task successfull moved to trash',
							icon: 'success',
						}).then(() => {
							onReload();
							setIsOpen(false);
						});
					})
					.catch(() => {})
					.finally(() => {});
			}
		});
	};

	const getSchedule = useCallback(() => {
		if (!taskCode) return;

		setLoading(true);

		ScheduleModule.list({
			boardCode,
			taskCode,
			groupByMonth: true,
		})
			.then((res) => {
				setDataSchedule(res.data.filter((d) => d.values.length > 0));
			})
			.catch(() => {})
			.finally(() => {
				setLoading(false);
			});
	}, [boardCode, taskCode]);

	const handleCreateSchedule = () => {
		setFormScheduleOptions(() => ({
			title: 'Create Schedule',
			initialValues: {
				title: '',
				date: '',
				is_all_day: false,
				time: {
					start: '',
					end: '',
				},
				repeat: null,
				reminder: [],
				assign_to: [],
				location: '',
				description: '',
			},
			handleCustomSubmit: (values, handleReset) => {
				setLoading(true);
				createSchedule(values)
					.then(() => {
						handleReset();
						getSchedule();
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			},
		}));
	};

	const handleEditSchedule = (item) => {
		setFormScheduleOptions(() => ({
			title: 'Edit Schedule',
			initialValues: {
				_id: item._id,
				title: item.title,
				date: item.date,
				is_all_day: item.is_all_day,
				time: item.time,
				repeat: item.repeat,
				reminder: item.reminder,
				assign_to: item.assign_to,
				location: item.location,
				description: item?.description,
			},
			handleCustomSubmit: (values, handleReset) => {
				setLoading(true);
				editSchedule(values)
					.then(() => {
						handleReset();
						getSchedule();
					})
					.catch(() => {})
					.finally(() => {
						setLoading(false);
					});
			},
		}));
	};

	const handleDetailSchedule = (item) => {
		setSelectedItemSchedule(() => ({ ...item }));
	};

	const handleDeleteSchedule = ({ _id }) => {
		deleteSchedule({ _id })
			.then(() => {
				getSchedule();
			})
			.catch(() => {});
	};

	useEffect(() => {
		getSchedule();
	}, [getSchedule]);

	useEffect(() => {
		if (formScheduleOptions.title !== 'Form Modal') setOpenFormSchedule(true);
	}, [formScheduleOptions]);

	useEffect(() => {
		if (Object.keys(selectedItemSchedule).length !== 0) setOpenDetailSchedule(true);
	}, [selectedItemSchedule]);

	return (
		<div>
			<Modal
				setIsOpen={handleClose}
				isOpen={isOpen}
				titleId={id}
				size={size}
				isFocus={false}
				enableEscape={false}
				isStaticBackdrop>
				<ModalHeader setIsOpen={handleClose}>
					<ModalTitle id={id}>{title}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					{loading && <LoadingContent onlyContent />}

					{!loading && dataTask && (
						<div className='row'>
							<div className='col-sm-12 col-lg-7'>
								<div className='row'>
									<div className='col-sm-12 py-1'>
										<DescriptionComponents
											label='Description'
											value={dataTask?.description}
											onUpdate={handleUpdate}
											role={role}
										/>
									</div>

									<div className='col-sm-12 border-bottom my-3' />

									<div className='col-sm-12 py-1'>
										<div className='py-1'>
											<Label className='fw-bold'>Attachment</Label>
										</div>
										<div className='mt-n3'>
											<FormText>
												({`Max size ${limit} with ${extentions} Extention`})
											</FormText>
										</div>
										<div className='py-1'>
											<Dropzone
												attachment={dataTask?.attachment}
												showAddButton
												mimeTypes={fileType.mimeTypes}
												maxLimit={fileType.limit}
												onUpdate={handleUpdate}
												role={role}
											/>
										</div>
									</div>

									<div className='col-sm-12 border-bottom my-3' />

									<div className='col-sm-12 py-1'>
										<ReferenceComponent
											data={dataTask?.reference}
											onUpdate={handleUpdate}
											role={role}
										/>
									</div>

									{dataSchedule.length ? (
										<div className='col-sm-12 py-1 mt-4'>
											<div className='py-1'>
												<Label className='fw-bold'>Schedule</Label>
											</div>
											<div className='py-1'>
												{dataSchedule.map((d) => (
													<div key={d.key} className='col-12'>
														<h3 className='fw-bold py-3'>{d.key}</h3>
														{d.values.map((item) => (
															<Item
																key={`${d.key}-Item-${item._id}`}
																item={item}
																handleDetail={handleDetailSchedule}
																handleEdit={handleEditSchedule}
																handleDelete={handleDeleteSchedule}
															/>
														))}
													</div>
												))}
											</div>
										</div>
									) : null}

									<div className='col-sm-12 py-1'>
										<div className='py-1'>
											<Label className='fw-bold'>Activity</Label>
										</div>
										<div className='py-1'>
											<ActivitiesComponent role={role} />
										</div>
									</div>
								</div>
							</div>

							<div className='col-sm-12 col-lg-5'>
								<div className='row'>
									<div className='col-sm-12'>
										<InformationComponent
											data={dataTask}
											onUpdate={handleUpdate}
											role={role}
										/>
									</div>
								</div>
								<div className='row'>
									<div className='col-sm-12'>
										<Button
											isLight
											icon='ContentCopy'
											color='info'
											onClick={() => copyUrlToClipboard(true)}
											className='m-2'>
											Copy Link
										</Button>

										{isBoardRoleAccess(currentUser?.role, [
											'owner',
											'member',
										]) && (
											<Button
												isLight
												icon='ArrowRightAlt'
												color='info'
												onClick={() => setOpenMoveModal(true)}
												className='m-2'>
												Move Task
											</Button>
										)}
										{isBoardRoleAccess(currentUser?.role, [
											'owner',
											'member',
										]) && (
											<Button
												isLight
												icon='CalendarToday'
												color='success'
												onClick={handleCreateSchedule}
												className='m-2'>
												Create Schedule
											</Button>
										)}
										{isBoardRoleAccess(currentUser?.role, [
											'owner',
											'member',
										]) && (
											<Button
												isLight
												icon='Delete'
												color='danger'
												onClick={handleTrash}
												className='m-2'>
												Move to Trash Bin
											</Button>
										)}
									</div>
								</div>
							</div>
						</div>
					)}

					{!loading && !dataTask && (
						<div className='w-100'>
							<Alert color='info' isLight icon='info'>
								No Data Found
							</Alert>
						</div>
					)}
				</ModalBody>
			</Modal>

			{/* MOVE TASK */}
			<MoveTaskModal
				isOpen={openMoveModal}
				setIsOpen={setOpenMoveModal}
				initValues={{
					board: { value: boardCode, label: boardTitle },
					status: dataTask?.status,
				}}
				listStatus={boardColumn}
				onUpdate={handleUpdate}
			/>

			<DetailModal
				isOpen={openDetailSchedule}
				setIsOpen={setOpenDetailSchedule}
				item={selectedItemSchedule}
			/>

			<FormModal
				isOpen={openFormSchedule}
				setIsOpen={setOpenFormSchedule}
				type='task'
				title={formScheduleOptions.title}
				initialValues={formScheduleOptions.initialValues}
				handleCustomSubmit={formScheduleOptions.handleCustomSubmit}
				boardCode={boardCode}
				taskCode={taskCode}
				detailBoard={detailBoard}
				getToken={getToken}
				isAuthenticated={isAuthenticated}
			/>
		</div>
	);
};

FormUpdateTask.propTypes = {
	id: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	setIsOpen: PropTypes.func.isRequired,
	size: PropTypes.oneOf([null, 'sm', 'lg', 'xl', 'xxl']),
	onReload: PropTypes.func,
	role: PropTypes.string,
	getToken: PropTypes.func,
	detailBoard: PropTypes.oneOf([PropTypes.object]),
	isAuthenticated: PropTypes.bool,
};
FormUpdateTask.defaultProps = {
	id: 'formUpdateTask',
	title: 'Title',
	size: 'xl',
	onReload: () => {},
	role: null,
	getToken: () => {},
	detailBoard: {},
	isAuthenticated: false,
};

export default FormUpdateTask;
