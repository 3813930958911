import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { decode } from 'html-entities';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert from '../../../components/bootstrap/Alert';
import Icon from '../../../components/icon/Icon';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Popovers from '../../../components/bootstrap/Popovers';
import Dropdown, {
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from '../../../components/bootstrap/Dropdown';
import Spinner from '../../../components/bootstrap/Spinner';
import AuthModule from '../../../modules/AuthModule';
import { getRequester } from '../../../helpers/helpers';
import UserModule from '../../../modules/UserModule';

const SwitchRole = () => {
	const { department, position_code, position_name, position, version, username } =
		getRequester();

	const [selectedRole, setSelectedRole] = useState([]);
	const [anotherRole, setAnotherRole] = useState([]);
	const [roles, setRoles] = useState([]);
	const [loading, setLoading] = useState(false);

	const onChange = async (_position_code) => {
		let _selectRole = selectedRole;
		let is_change = false;
		const new_roles = roles.map((e) => {
			let is_selected = false;
			if (e?.position_code === _position_code) {
				_selectRole = e;
				is_selected = true;
				is_change = true;
			}
			return { ...e, is_selected };
		});

		if (is_change) {
			const params = new URLSearchParams({
				role: _selectRole?.role_name,
			});
			AuthModule.getMenu(params)
				.then(() => {
					localStorage.setItem('roles', JSON.stringify(_selectRole?.role_name));
					localStorage.setItem('position', JSON.stringify(_selectRole));
					localStorage.setItem('list_roles', JSON.stringify(new_roles));
					window.location.href = '/home';
				})
				.catch(() => {
					Swal.fire({
						heightAuto: false,
						title: 'Warning!',
						text: 'Role not found',
						icon: 'info',
					});
				})
				.finally(() => {});
		}
	};

	useEffect(() => {
		fetchData('no');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fetchData = (type) => {
		if (type !== 'no') {
			setLoading(true);
		}
		UserModule.getPosition({ username })
			.then((res) => {
				setRoles(res);
				const check = res.find((e) => e?.position_code === position_code);
				const checkAnother = res.filter((e) => e?.position_code !== position_code);
				if (check) {
					setSelectedRole(check);
				} else {
					onChange(checkAnother[0]?.position_code);
				}
				setAnotherRole(checkAnother);
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<div className='col-auto'>
			<Dropdown direction='down'>
				<DropdownToggle hasIcon>
					<div>
						{loading ? (
							<Button color='primary' onClick={fetchData}>
								<div style={{ size: '10px' }}>
									<Spinner isSmall inButton='onlyIcon' isGrow />
									&nbsp;
									<Spinner isSmall inButton='onlyIcon' isGrow />
									&nbsp;
									<Spinner isSmall inButton='onlyIcon' isGrow />
									&nbsp; Loading...
								</div>
							</Button>
						) : (
							<Button color='primary' onClick={fetchData}>
								<div>
									{version === 3 ? position?.org_name_short : department} -{' '}
									{version === 3
										? decode(position?.position_name)
										: decode(position_name)}
								</div>
							</Button>
						)}
					</div>
				</DropdownToggle>
				<DropdownMenu>
					{!loading &&
						anotherRole &&
						anotherRole?.length > 0 &&
						anotherRole.map((e, i) => (
							<DropdownItem key={`index_${i + 1}`} isHeader isDivider isText>
								<Button onClick={() => onChange(e?.position_code)}>{`(${
									version === 3 ? e?.org_name_short : e?.department_name
								}) - ${decode(e?.position_name)}`}</Button>
							</DropdownItem>
						))}{' '}
				</DropdownMenu>
			</Dropdown>
		</div>
	);
};

// eslint-disable-next-line react/prop-types
const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();

	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}

				{/*  Role Switch */}
				<div className='col-auto d-flex flex-column justify-content-center'>
					<h5>You are logged in as</h5>
				</div>
				<SwitchRole />

				{/* Dark Mode */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Dark / Light mode'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							onClick={() => setDarkModeStatus(!darkModeStatus)}
							className='btn-only-icon'
							data-tour='dark-mode'>
							<Icon
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								color={darkModeStatus ? 'info' : 'warning'}
								className='btn-icon'
							/>
						</Button>
					</Popovers>
				</div>

				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/*	Notifications */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div>
				{afterChildren}
			</div>

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<Alert icon='ViewInAr' isLight color='info' className='flex-nowrap'>
						4 new components added.
					</Alert>
				</OffCanvasBody>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
