const moment = require('moment-timezone');

// Helper function to format date
const formatDate = (date) => moment(date).format('DD MMM YYYY').tz('Asia/Jakarta');

// Factory function to create activities
const createActivity = (activityType, generateActivityDetail) => ({
	activityType,
	generateActivityDetail,
});

// Define activities using functional approach
const activityTask = {
	createTask: createActivity(
		'Create Task',
		({ username, taskName }) => `${username} created ${taskName}`,
	),
	editTaskInformation: createActivity(
		'Edit Task Information',
		({ username, taskName }) => `${username} edited ${taskName} information`,
	),
	editTaskDescription: createActivity(
		'Edit Task Description',
		({ username, taskName }) => `${username} edited ${taskName} description`,
	),
	changePriority: createActivity(
		'Change Priority',
		({ username, before, after }) => `${username} changed priority ${before} to ${after}`,
	),
	changeStatus: createActivity(
		'Change Status',
		({ username, before, after }) => `${username} changed status ${before} to ${after}`,
	),
	addAssignee: createActivity(
		'Add Assignee',
		({ username, usernameAdded }) => `${username} added assignee: ${usernameAdded}`,
	),
	changeAssignee: createActivity(
		'Change Assignee',
		({ username, usernameChanged }) => `${username} changed assignee: ${usernameChanged}`,
	),
	changeDueDate: createActivity(
		'Change Due Date',
		({ username, date }) => `${username} changed due date to ${formatDate(date)}`,
	),
	addAttachment: createActivity(
		'Add Attachment',
		({ username, fileName }) => `${username} added attachment ${fileName}`,
	),
	deleteAttachment: createActivity(
		'Delete Attachment',
		({ username, fileName }) => `${username} deleted attachment ${fileName}`,
	),
	addReference: createActivity(
		'Add Reference',
		({ username, link }) => `${username} added reference ${link}`,
	),
	editReference: createActivity(
		'Edit Reference',
		({ username, link }) => `${username} updated reference ${link}`,
	),
	deleteReference: createActivity(
		'Delete Reference',
		({ username, link }) => `${username} deleted reference ${link}`,
	),
	moveTask: createActivity(
		'Move Task',
		({ username, taskName }) => `${username} moved task to ${taskName}`,
	),
	createSchedule: createActivity(
		'Create Schedule',
		({ username, scheduleName }) => `${username} added schedule ${scheduleName}`,
	),
	editSchedule: createActivity(
		'Edit Schedule',
		({ username, scheduleName, date }) =>
			`${username} edited schedule: ${scheduleName} moved ${formatDate(date)}`,
	),
	deleteSchedule: createActivity(
		'Delete Schedule',
		({ username, scheduleName }) => `${username} deleted schedule: ${scheduleName}`,
	),
	movedToTrashBin: createActivity(
		'Moved to Trash Bin',
		({ username, taskName }) => `${username} moved task ${taskName} to bin`,
	),
	addComment: createActivity('Add Comment', ({ username }) => `${username} add comment`),
	editComment: createActivity('Edit Comment', ({ username }) => `${username} edit comment`),
	deleteComment: createActivity('Delete Comment', ({ username }) => `${username} delete comment`),
};

module.exports = activityTask;
