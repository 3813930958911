import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import Input from '../../../components/bootstrap/forms/Input';
import Checks, { ChecksGroup } from '../../../components/bootstrap/forms/Checks';
import Alert from '../../../components/bootstrap/Alert';
import Icon from '../../../components/icon/Icon';
import { copyUrlToClipboard, readFromLocalStorage, TimeAgo, writeToLocalStorage } from '../utils';
import Avatar from '../../../components/Avatar';

import USERS from '../../../common/data/userDummyData';
import Badge from '../../../components/bootstrap/Badge';
import Actions from './Actions';
import showNotification from '../../../components/extras/showNotification';
import CloseBoardModule from '../../../modules/ninda/CloseBoardModule';
import { isBoardRoleAccess } from '../../../helpers/board-role';

import BoardModule from '../../../modules/bakti/BoardModule';
import TaskModule from '../../../modules/bakti/TaskModule';
import { getQueryParams } from '../../../helpers/helpers';
import { TaskCard } from './TaskBoard';
import { leaveBoard } from '../../../enums/activityBoard';
import Popovers from '../../../components/bootstrap/Popovers';

const COLOR_USERS = {
	guest: 'light',
	spectator: 'warning',
	member: 'success',
	owner: 'info',
};

const ChecksComponent = ({ id, list, value, onChange, isInline, description }) => {
	return (
		<div>
			<ChecksGroup isInline={isInline}>
				{list.map((item) => (
					<Checks
						name={id}
						key={`check-${id}-${item.value}`}
						type='radio'
						id={`${id}${item.value}`}
						label={item.label}
						value={item.value}
						checked={value}
						onChange={onChange}
					/>
				))}
			</ChecksGroup>

			{description && (
				<div className='mt-2'>
					<Alert isLight color='info'>
						<div className='d-flex align-items-center'>
							<Icon icon='Info' color='info' size='2x' />
							<div className='ms-2'>{description}</div>
						</div>
					</Alert>
				</div>
			)}
		</div>
	);
};

ChecksComponent.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	list: PropTypes.oneOfType([PropTypes.array]),
	value: PropTypes.string,
	onChange: PropTypes.func,
	isInline: PropTypes.bool,
	description: PropTypes.string,
};
ChecksComponent.defaultProps = {
	id: 'id',
	list: [],
	value: '',
	onChange: () => {},
	isInline: false,
	description: false,
};

const FieldComponents = ({ label, value, onChange, pattern, role }) => {
	const [data, setData] = useState(value);
	const [showEdit, setShowEdit] = useState(false);

	const [isValid, setIsValid] = useState(true);
	const [invalidMessaage, setInvalidMessage] = useState('');

	const handleSave = () => {
		if (!data || !data?.trim()) {
			setIsValid(false);
			setInvalidMessage('Required');
		} else {
			setIsValid(true);
			setInvalidMessage('');

			let newValue = data?.trim();
			if (pattern) newValue = newValue.replace(/ /g, '_');
			onChange(newValue);
			setShowEdit(false);
		}
	};

	const handleCancel = () => {
		setData(value);
		setShowEdit(false);
		setIsValid(true);
		setInvalidMessage('');
	};

	return (
		<div>
			<div className='d-flex align-items-center py-1'>
				<div className='flex-grow-1'>
					<Label>{label}</Label>
				</div>

				{isBoardRoleAccess(role, ['owner'])
					? !showEdit && (
							<div>
								<Button
									isOutline
									icon='Edit'
									color='info'
									type='button'
									size='sm'
									className='mx-1'
									onClick={() => setShowEdit(true)}
								/>
							</div>
					  )
					: null}
				{/* {!showEdit && (
					<div>
						<Button
							isOutline
							icon='Edit'
							color='info'
							type='button'
							size='sm'
							className='mx-1'
							onClick={() => setShowEdit(true)}
						/>
					</div>
				)} */}
			</div>

			{!showEdit && <div className='py-1'>{value}</div>}

			{showEdit && (
				<div className='py-1'>
					<Input
						value={data}
						onChange={(e) => {
							if (pattern) {
								if (pattern.test(e.target.value)) {
									setData(e.target.value);
								}
							} else {
								setData(e.target.value);
							}
						}}
						isValid={isValid}
						isTouched={!!invalidMessaage}
						invalidFeedback={invalidMessaage}
					/>
				</div>
			)}

			{showEdit && (
				<div className='py-1'>
					<Button
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleSave}>
						Save
					</Button>

					<Button
						isLink
						color='info'
						type='button'
						size='sm'
						className='mx-1'
						onClick={handleCancel}>
						Cancel
					</Button>
				</div>
			)}
		</div>
	);
};

FieldComponents.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	pattern: PropTypes.string,
	role: PropTypes.string,
};
FieldComponents.defaultProps = {
	label: 'Label',
	value: '',
	onChange: () => {},
	pattern: null,
	role: null,
};

const TrashComponent = () => {
	const { board_code: boardCode } = getQueryParams();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);

				TaskModule.findAllTrashByBoardCode(boardCode, {})
					.then((response) => {
						setData(response);
					})
					.catch(() => {
						setData([]);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				//
			}
		};

		fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{!loading &&
				data.length > 0 &&
				data.map((task) => <TaskCard task={task} isDraggable={false} hideModal />)}

			{!loading && data.length === 0 && (
				<div className='w-100'>
					<Alert color='info' isLight icon='info'>
						No Data Found
					</Alert>
				</div>
			)}
		</div>
	);
};

TrashComponent.propTypes = {};
TrashComponent.defaultProps = {};

const ActivityComponent = () => {
	const { board_code: boardCode } = getQueryParams();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetch = async () => {
			try {
				setLoading(true);

				BoardModule.findActivityByBoardCode(boardCode)
					.then((response) => {
						setData(response.activities || []);
					})
					.catch(() => {
						setData([]);
					})
					.finally(() => {
						setLoading(false);
					});
			} catch (err) {
				//
			}
		};

		fetch();

		return () => {};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div>
			{!loading &&
				data.length > 0 &&
				data.map((item, index) => (
					<div key={'index'.concat(index)} className='py-1 d-flex align-items-center'>
						<div className='me-1'>
							<Avatar
								src={USERS.JOHN.src}
								srcSet={USERS.JOHN.srcSet}
								color={USERS.JOHN.color}
								size={36}
							/>
						</div>
						<div className='ms-1'>
							<div className='fw-bold'>{item?.actor?.name}</div>
							<div className='small'>{item?.detail}</div>
							<div className='small text-muted'>{TimeAgo(item?.created_at)}</div>
						</div>
					</div>
				))}

			{!loading && data.length === 0 && (
				<div className='w-100'>
					<Alert color='info' isLight icon='info'>
						No Data Found
					</Alert>
				</div>
			)}
		</div>
	);
};

ActivityComponent.propTypes = {};
ActivityComponent.defaultProps = {};

const BoardMenu = ({ isOpen, setIsOpen, data, onReload, role, setLoading }) => {
	const navigate = useNavigate();
	const currentUser = readFromLocalStorage('board_user');
	const listMembers = readFromLocalStorage('board_members');

	const [menu] = useState({
		Main: 'Menu',
		BoardInformation: 'Board Information',
		Activity: 'Activity',
		TrashBin: 'Trash Bin',
	});

	const formik = useFormik({
		initialValues: {
			title: data?.title || '',
			board_key: data?.board_key || '',
			members: data?.members || [],
			visibility: data?.visibility || '',
		},
		enableReinitialize: true,
		onReset: () => {},
		onSubmit: () => {},
	});

	const [activeMenu, setActiveMenu] = useState(menu.Main);

	const [listVisibility] = useState([
		{
			value: 'private',
			label: 'Private',
			description: 'Only board members can see this board',
		},
		{
			value: 'public',
			label: 'Public',
			description: 'Any one can see this board, only board members can edit',
		},
	]);

	const [isChange, setIsChange] = useState(false);

	const handleClose = () => {
		if (isChange) {
			setIsChange(false);
			onReload();
		}
		setActiveMenu(menu.Main);
		setIsOpen(false);
	};

	const handleChange = (key, value) => {
		// update here
		Actions.updateBoard(data?.trans_code, {
			[key]: value,
			actor: JSON.stringify(currentUser),
		})
			.then(() => {
				formik.setFieldValue(key, value);
				if (key === 'board_key') {
					writeToLocalStorage('board_key', value);
				}
				setIsChange(true);
			})
			.catch(() => {
				setIsChange(false);
			})
			.finally(() => {});
	};

	const handleLeaveBoard = () => {
		Swal.fire({
			heightAuto: false,
			title: 'Are you sure?',
			text: `You want to leave from this board?`,
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#00941b',
			confirmButtonText: 'Yes, Confirm',
			cancelButtonColor: '#d33',
			cancelButtonText: `No, Cancel`,
			reverseButtons: true,
		}).then((result) => {
			if (result.isConfirmed) {
				const activity_type = leaveBoard.activityType;
				const activity_detail = leaveBoard.generateActivityDetail({
					username: currentUser.name,
					boardName: data.title,
				});
				BoardModule.leaveOneByBoardCode(data?.trans_code, {
					// default
					actor: JSON.stringify(currentUser),
					activity_type,
					activity_detail,
				})
					.then(() => {
						setActiveMenu(menu.Main);
						setIsOpen(false);
						setIsChange(true);
						Swal.fire({
							heightAuto: false,
							title: 'Success!',
							text: `You successfully leave board ${data.title}`,
							icon: 'success',
							confirmButtonText: 'Okay',
						}).then(() => {
							navigate('/board/workflow');
						});
					})
					.catch(() => {})
					.finally(() => {});
			}
		});
	};

	const closeBoard = () => {
		if (!data?.isWaitingApprovalClose) {
			Swal.fire({
				title: 'Are you sure?',
				text: 'Do you want to close this board?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes',
			})
				.then((result) => {
					if (result.isConfirmed) {
						setLoading(true);
						// update data close board
						CloseBoardModule.closeBoard({ board_code: data.trans_code })
							.then(() => {
								setIsOpen(false);
								showNotification(
									'Success!',
									'Data has been saved Successfully',
									'success',
								);
								navigate(`/board/workflow?board=${data.trans_code}`);
							})
							.catch((err) => {
								Swal.fire('Warning', err, 'error');
							})
							.finally(() => {});
					} else {
						Swal.fire('Cancelled', 'Your data is safe :)', 'error');
					}
				})
				.catch(() => {
					Swal.fire('Cancelled', 'Your data is safe :)', 'error');
				});
		}
	};
	return (
		<OffCanvas
			isOpen={isOpen}
			setOpen={handleClose}
			titleId='canvasMennu'
			isBodyScroll
			placement='end'
			isFocus={false}>
			<OffCanvasHeader
				onBack={() => setActiveMenu(menu.Main)}
				showBack={activeMenu !== menu.Main}
				titleCenter
				setOpen={handleClose}>
				<OffCanvasTitle id='canvasMennu'>{activeMenu}</OffCanvasTitle>
			</OffCanvasHeader>
			<OffCanvasBody>
				<div className='border-bottom mb-3' />

				{/* MAIN MENU */}
				{activeMenu === menu.Main && (
					<div className='row'>
						<div className='col-12'>
							<Button
								icon='InfoOutline'
								color='info'
								onClick={() => setActiveMenu(menu.BoardInformation)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Board Information
							</Button>
						</div>

						{isBoardRoleAccess(role, ['owner', 'member', 'spectator']) ? (
							<div className='col-12'>
								<Button
									icon='Restore'
									color='info'
									onClick={() => setActiveMenu(menu.Activity)}
									className='w-100 p-2 text-start d-flex align-items-center'
									isLink>
									Activity
								</Button>
							</div>
						) : null}

						<div className='col-12'>
							<Button
								icon='ContentCopy'
								color='info'
								onClick={() => copyUrlToClipboard(true)}
								className='w-100 p-2 text-start d-flex align-items-center'
								isLink>
								Copy Board Link
							</Button>
						</div>
						{isBoardRoleAccess(role, ['owner', 'member']) && (
							<div className='col-12'>
								<Button
									icon='Delete'
									color='info'
									onClick={() => setActiveMenu(menu.TrashBin)}
									className='w-100 p-2 text-start d-flex align-items-center'
									isLink>
									Trash Bin
								</Button>
							</div>
						)}

						{isBoardRoleAccess(role, ['owner']) && !data?.is_closed ? (
							<div className='col-12 d-flex'>
								<Button
									isOutline
									onClick={() => closeBoard()}
									icon='Close'
									color='info'
									className='w-100 p-2 text-start d-flex align-items-center'
									isLink>
									Close Board
									{'  '}
									{data?.isWaitingApprovalClose && (
										<Popovers trigger='hover' desc='Waiting Approval'>
											<small className='p-1'>
												<Icon
													icon='History'
													color='info'
													className='me-2'
												/>
											</small>
										</Popovers>
									)}
								</Button>
							</div>
						) : null}

						{isBoardRoleAccess(role, ['member', 'spectator']) && !data?.is_closed ? (
							<div className='col-12'>
								<Button
									icon='Logout'
									color='info'
									onClick={handleLeaveBoard}
									className='w-100 p-2 text-start d-flex align-items-center'
									isLink>
									Leave Board
								</Button>
							</div>
						) : null}
					</div>
				)}

				{/* BOARD INFORMATION */}
				{activeMenu === menu.BoardInformation && (
					<div>
						<FieldComponents
							label='Board Title'
							id='title'
							value={formik.values.title}
							onChange={(e) => handleChange('title', e)}
							role={role}
						/>
						<div className='border-bottom my-4' />

						<FieldComponents
							label='Board Key'
							id='board_key'
							value={formik.values.board_key}
							onChange={(e) => handleChange('board_key', e)}
							pattern={/^[A-Za-z\s]*$/}
							role={role}
						/>
						<div className='border-bottom my-4' />

						<ChecksComponent
							id='visibility'
							list={listVisibility}
							value={formik.values.visibility}
							onChange={(e) => {
								if (isBoardRoleAccess(role, ['owner'])) {
									handleChange('visibility', e.target.value);
								}
							}}
							isInline
							description={
								listVisibility.find((f) => f.value === formik.values.visibility)
									?.description
							}
						/>
						<div className='border-bottom my-4' />

						{listMembers.map((user) => (
							<div key={user?.username} className='d-flex align-items-center'>
								<div className='flex-grow-1'>
									<div className='d-flex'>
										<div className='flex-shrink-0'>
											<Avatar
												src={USERS.JOHN.src}
												srcSet={USERS.JOHN.srcSet}
												color={USERS.JOHN.color}
												size={36}
											/>
										</div>
										<div className='mx-2'>
											<div>{user?.name}</div>
											<div className='small text-muted'>{user?.username}</div>
										</div>
									</div>
								</div>
								<div className='mx-1'>
									<Badge color={user?.color || COLOR_USERS[user?.role]}>
										{user?.role}
									</Badge>
								</div>
							</div>
						))}
						<div className='border-bottom my-4' />
					</div>
				)}

				{/* ACTIVITY */}
				{activeMenu === menu.Activity && <ActivityComponent />}

				{/* TRASH BIN */}
				{activeMenu === menu.TrashBin && <TrashComponent />}
			</OffCanvasBody>
		</OffCanvas>
	);
};

BoardMenu.propTypes = {
	isOpen: PropTypes.bool,
	setIsOpen: PropTypes.func,
	data: PropTypes.oneOfType([PropTypes.object]),
	onReload: PropTypes.func,
	role: PropTypes.string,
	setLoading: PropTypes.func,
};
BoardMenu.defaultProps = {
	isOpen: false,
	setIsOpen: () => false,
	data: null,
	onReload: () => {},
	role: null,
	setLoading: () => {},
};

export default BoardMenu;
