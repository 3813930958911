import React from 'react';
import moment_tz from 'moment-timezone';
import DOMPurify from 'dompurify';

import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import Badge from '../../../components/bootstrap/Badge';

const timeZone = 'Asia/Jakarta';

export const copyUrlToClipboard = (showNotif) => {
	const url = window.location.href;
	navigator.clipboard
		.writeText(url)
		.then(() => {
			if (showNotif)
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='VerifiedUser' size='2x' className='me-1' />
						<span>
							<h5>Link successfully copied</h5>
						</span>
					</span>,
					'',
					'success',
				);
		})
		.catch((err) => {
			if (showNotif) showNotification('Failed to copy', err, 'danger');
		});
};

export const openLink = (url) => {
	// Check if the URL contains 'http' or 'https'
	const formattedUrl =
		url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

	// Open the link in a new tab
	window.open(formattedUrl, '_blank');
};

export const TimeAgo = (timestamp, currentDate) => {
	const now = moment_tz(currentDate || new Date()).tz(timeZone);
	const diff = moment_tz.duration(now.diff(timestamp));

	const formatTime = () => {
		if (diff.asSeconds() < 60) {
			return 'just now'; // 1-59 seconds
		}
		if (diff.asMinutes() < 60) {
			return `${Math.floor(diff.asMinutes())} minute ago`; // 1-59 minutes
		}
		if (diff.asHours() < 24) {
			return `${Math.floor(diff.asHours())} hour ago`; // 1-23 hours
		}
		if (diff.asDays() < 7) {
			return `${Math.floor(diff.asDays())} days ago`; // 1-7 days
		}
		return moment_tz(timestamp).tz(timeZone).format('MMMM D, YYYY [at] h:mm A'); // > 7 days
	};

	return <span>{formatTime()}</span>;
};

export const FormatDate = (timestamp, isFull) => {
	if (!timestamp) return '-';

	const format = isFull ? 'DD MMMM YYYY, h:mm' : 'DD MMMM YYYY';
	const time = moment_tz(timestamp).tz(timeZone).format(format);

	return <span>{time}</span>;
};

export const DueDate = (timestamp) => {
	const time = moment_tz(timestamp).tz(timeZone).format('DD MMM YY');

	return <span>{time}</span>;
};

export const ListTags = (tags) => {
	if (!tags || !tags?.length) return '-';

	if (Array.isArray(tags)) {
		return tags.map((e) => <div key={e.value}>{e.label}</div>);
	}

	return tags.label;
};

export const ListAssigne = (user) => {
	if (!user || !user?.length) return '-';

	if (Array.isArray(user)) {
		return user.map((u) => <div key={u.username}>{`${u?.username} - ${u?.name}`}</div>);
	}

	return `${user?.username} - ${user?.name}`;
};

export const ListReporter = (user) => {
	if (!user) return '-';

	return `${user?.username} - ${user?.name}`;
};

export const ListMember = (members) => {
	if (!members?.length) return '-';

	if (Array.isArray(members)) {
		return members.map((u) => <div key={u.username}>{`${u?.username} - ${u?.name}`}</div>);
	}

	return `${members?.username} - ${members?.name}`;
};

export const writeToLocalStorage = (key, value) => {
	localStorage.setItem(key, JSON.stringify(value));
};

export const readFromLocalStorage = (key) => {
	const value = localStorage.getItem(key);

	if (value && value !== 'undefined') {
		return JSON.parse(value);
	}

	return null;
};

export const removeFromLocalStorage = (key) => {
	localStorage.removeItem(key);
};

export const capitalizeFirstLetter = (str) => {
	if (!str) return '';
	return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 *
 * @param {Array<Object>} files
 * @param {Array<Object>} sourceFile
 */
export const base64ArrayToFileArray = (files, sourceFile) => {
	const result = files.map((item, index) => {
		// Decode the Base64 content
		const byteCharacters = atob(item.file);
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const blob = new Blob([byteArray], { type: item.mimetype });

		// Create a File object using the filename and mimetype from JSON
		const file = new File([blob], item.filename, { type: item.mimetype });

		// create thumbnails
		let thumbnails = null;
		if (['image/jpeg', 'image/png'].includes(item.mimetype)) {
			thumbnails = `data:${item.mimetype};base64,${item.file}`;
		}

		return { data: file, file: sourceFile[index], content: thumbnails };
	});

	return result;
};

export const fileToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result.split(',')[1]); // Remove the data URL prefix
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsDataURL(file);
	});
};

/**
 *
 * @param {String} roleCode
 * @returns
 */
export const getAllowedRole = (roleCode) => {
	const rules = readFromLocalStorage('board_rules');

	const findRole = [...rules].find((f) => f.value === roleCode);

	return {
		value: findRole?.value || '',
		label: findRole?.label || '',
		isAllowed: findRole?.isAllowed || false,
	};
};

export const toTime = (date) => {
	return moment_tz.tz(date, 'Asia/Jakarta').locale('id').format('HH:mm');
};

export const toDateShort = (date, isDefault, isEmpty) => {
	if (isEmpty && !date) return '-';
	if (isDefault) return moment_tz.tz(date, 'Asia/Jakarta').locale('id').format('YYYY-MM-DD');
	return moment_tz.tz(date, 'Asia/Jakarta').locale('id').format('DD MMM YYYY');
};

export const getStatus = (status) => {
	let color = 'success';
	if (status === 'Rejected') color = 'danger';

	return <Badge color={color}>{status}</Badge>;
};

export const addHttpsToLinks = (html) => {
	return html.replace(/href="(?!https?:\/\/)([^"]*)"/g, (match, p1) => {
		return `href="https://${p1}"`;
	});
};

export const cleanHTML = (html) => {
	return DOMPurify.sanitize(addHttpsToLinks(html), {
		ADD_ATTR: ['target'], // Allow target attribute
	});
};

export default {};
